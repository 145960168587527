import React, { createContext, useContext, useState } from 'react';

// Create the context
const CaptchaContext = createContext();

// Create a provider component
export const CaptchaProvider = ({ children }) => {

    const [isValidCaptcha, setIsValidCaptcha] = useState('valid');

    return (
        <CaptchaContext.Provider value={{ isValidCaptcha, setIsValidCaptcha }}>
            {children}
        </CaptchaContext.Provider>
    );
};

// Custom hook to use the CaptchaContext
export const useCaptcha = () => useContext(CaptchaContext);
