import { Grid, Typography } from '@mui/material'
import React from 'react'
import FullName from './FullName'
import DatePickers from './DatePicker'
import Address from './Address'
import SingleChoice from './SingleChoice'
import FileUpload from './FileUpload'
import Email from './Email';
import DropDown from './DropDown'
import ShortText from './ShortText'
import LongText from './LongText'
import Heading from './Heading'

const FootballRegistrationForm = () => {


    const FootballOptions = [
        'مهد کودک',
        'راهنمایی',
        'دبیرستان',
        'دانشگاه',
    ]



    const FootballSingleData = [
        { id: 1, label: 'حمله', checked: false },
        { id: 2, label: 'دفاع', checked: false },
        { id: 3, label: "نمی دانم", checked: false },
    ]


    const FootballGenderSingleData = [
        { id: 1, label: 'آقا', checked: false },
        { id: 2, label: 'خانم', checked: false },
        { id: 3, label: "نمی‌خواهید مشخص کنید", checked: false },
    ]





    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم ثبت نام فوتبال'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام بازیکن'} />
                <DatePickers header={'تاریخ تولد'} />
                <Address header={'آدرس منزل'}/>
                <SingleChoice header={'جنسیت'} options={FootballGenderSingleData}/>
                <FileUpload header={'لطفا عکس خود را آپلود کنید'} />
                <Email header={'ایمیل بازیکن'}/>
                <DropDown header={'گروه تیم بازیکنان'} options={FootballOptions} />
                <SingleChoice header={'حمله یا دفاع'} options={FootballSingleData}/>
                <ShortText header={'قد'} />
                <ShortText header={'وزن'} />
                <LongText header={'نظرات'} />
            </Grid>
        </Grid>
    )
}

export default FootballRegistrationForm