import { Button, Grid } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormLimit } from '../Context/FormLimitContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';

const CreateFormButton = () => {


    const { canCreateForm } = useFormLimit();


    const navigate = useNavigate()



    const CustomToast = ({ message, state }) => {
        return (
            <Grid display="flex" alignItems="start" justifyContent="end" gap="5px" style={{
                backgroundColor: 'white',
                color: state === 'success' ? 'green' : 'red',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
            }}>
                {state === 'success' ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
                {message}
            </Grid>
        );
    };


    const handleCountForm = () => {
        if (!canCreateForm) {
            toast(<CustomToast message={'شما نمی‌توانید فرم جدیدی ایجاد کنید. حداکثر تعداد ۱۰ فرم مجاز است.'} state={'error'} />);
        }else{
            navigate('/createForm')
        }
    }


    return (
        <Grid width={{ xs: '100%' }} maxWidth={{ md: '300px' }} p={{ xs: '14px 10px', md: '8px 13px' }} display={{ xs: 'flex' }} alignItems={'center'} justifyContent={'center'} bgcolor={{ xs: '#f3f3fe', md: 'white' }} borderBottom={{ xs: '1px solid #c8ceed', md: 'none' }} >
            <Button
                onClick={handleCountForm}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '42px', p: '14px', bgcolor: '#ff6100', color: 'white', fontSize: '16px', borderRadius: '4px', '&:hover': { bgcolor: '#DC5F00' } }}
            >
                فرم ایجاد کنید
            </Button>
        </Grid>
    )
}

export default CreateFormButton