import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import 'flag-icons/css/flag-icons.min.css';
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Theme from "./Theme/Theme";
import { RouterProvider } from "react-router-dom";
import router from "./router/router";
import { ToastContainer } from "react-toastify";
import { TitleProvider } from "./Context/TitleContext";
import { EmailProvider } from "./Context/EmailContext";
import { FileUploadProvider } from "./Context/FileUploadContext";
import { ComponentTitleProvider } from "./Context/ComponentTitleContext";
import { SelectedOptionProvider } from "./Context/SelectedOptionContext";
import { PayloadProvider } from "./Context/PayloadContext";
import { InputValuesProvider } from "./Context/InputValuesContext";
import { ErrorProvider } from "./Context/ErrorContext";
import { SelectValuesProvider } from "./Context/SelectValuesContext";
import { FinalValuesProvider } from "./Context/FinalValuesContext";
import { ThankYouInitialContentProvider } from "./Context/ThankYouInitialContentContext";
import { ReadOnlyProvider } from "./Context/ReadOnlyContext";
import { FilteredValuesProvider } from "./Context/FilteredValuesContext";
import { NotificationEmailProvider } from "./Context/NotificationEmailContext";
import { AutoResponderProvider } from "./Context/AutoResponderContext";
import { ThankYouConditionEditorProvider } from "./Context/ThankYouConditionEditorContext";
import { UrlPayloadProvider } from "./Context/UrlPayloadContext";
import { ThankYouMessageProvider } from "./Context/ThankYouMessageContext";
import { SenderNotificationEmailProvider } from "./Context/SenderNotificationEmailContext";
import { AdvanceNotificationEmailProvider } from "./Context/AdvanceNotificationEmail";
import { DataProvider } from "./Context/DataContext";
import { DrawerProvider } from "./Context/DrawerContext";
import { DraftProvider } from "./Context/DraftContext";
import { FormProvider } from "./Context/Context";
import { SelectedItemProvider } from "./Context/SelectedItemContext";
import { EditModeProvider } from "./Context/EditModeContext";
import { SwitchFormProvider } from './Context/SwitchFormContext';
import { UnifiedDataProvider } from "./Context/UnifiedDataContext";
import { RemovedItemsProvider } from "./Context/RemovedItemsContext";
import { ArchivedItemsProvider } from "./Context/ArchivedItemsContext";
import { FavoriteItemsProvider } from "./Context/FavoriteItemsContext";
import { CountryProvider } from "./Context/CountryContext";
import { CaptchaProvider } from './Context/CaptchaContext';
import { FormLimitProvider } from "./Context/FormLimitContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FormLimitProvider>
      <CaptchaProvider>
        <CountryProvider>
          <FavoriteItemsProvider>
            <ArchivedItemsProvider>
              <RemovedItemsProvider>
                <UnifiedDataProvider>
                  <SwitchFormProvider>
                    <EditModeProvider>
                      <SelectedItemProvider>
                        <FormProvider>
                          <DraftProvider>
                            <DrawerProvider>
                              <DataProvider>
                                <AdvanceNotificationEmailProvider>
                                  <SenderNotificationEmailProvider>
                                    <ThankYouMessageProvider>
                                      <UrlPayloadProvider>
                                        <ThankYouConditionEditorProvider>
                                          <AutoResponderProvider>
                                            <NotificationEmailProvider>
                                              <FilteredValuesProvider>
                                                <ReadOnlyProvider>
                                                  <ThankYouInitialContentProvider>
                                                    <FinalValuesProvider>
                                                      <SelectValuesProvider>
                                                        <ErrorProvider>
                                                          <InputValuesProvider>
                                                            <PayloadProvider>
                                                              <SelectedOptionProvider>
                                                                <ComponentTitleProvider>
                                                                  <TitleProvider>
                                                                    <EmailProvider>
                                                                      <FileUploadProvider>
                                                                        <ThemeProvider theme={Theme}>
                                                                          <ToastContainer />
                                                                          <CssBaseline />
                                                                          <RouterProvider router={router} />
                                                                        </ThemeProvider>
                                                                      </FileUploadProvider>
                                                                    </EmailProvider>
                                                                  </TitleProvider>
                                                                </ComponentTitleProvider>
                                                              </SelectedOptionProvider>
                                                            </PayloadProvider>
                                                          </InputValuesProvider>
                                                        </ErrorProvider>
                                                      </SelectValuesProvider>
                                                    </FinalValuesProvider>
                                                  </ThankYouInitialContentProvider>
                                                </ReadOnlyProvider>
                                              </FilteredValuesProvider>
                                            </NotificationEmailProvider>
                                          </AutoResponderProvider>
                                        </ThankYouConditionEditorProvider>
                                      </UrlPayloadProvider>
                                    </ThankYouMessageProvider>
                                  </SenderNotificationEmailProvider>
                                </AdvanceNotificationEmailProvider>
                              </DataProvider>
                            </DrawerProvider>
                          </DraftProvider>
                        </FormProvider>
                      </SelectedItemProvider>
                    </EditModeProvider>
                  </SwitchFormProvider>
                </UnifiedDataProvider>
              </RemovedItemsProvider>
            </ArchivedItemsProvider>
          </FavoriteItemsProvider>
        </CountryProvider>
      </CaptchaProvider>
    </FormLimitProvider>
  </React.StrictMode>
);
reportWebVitals();
