import { Grid, Typography } from '@mui/material'
import React from 'react'
import FullName from './FullName'
import Email from './Email'
import Phone from './Phone'
import DatePickers from './DatePicker'
import Number from './Number';
import MultiChoice from './MultiChoice';
import SingleChoice from './SingleChoice'
import LongText from './LongText'
import Heading from './Heading'

const TourReservationForm = () => {


    const MultiDataTourReservation = [
        { id: 1, label: 'انقلاب تازه شروع بود', checked: false },
        { id: 2, label: 'ماورایی', checked: false },
        { id: 3, label: 'سنت', checked: false },
        { id: 5, label: 'دروازه‌ها، انگورها و قبرستان‌ها', checked: false },
    ]



    const MultiDataContactTourReservation = [
        { id: 1, label: 'تلفن', checked: false },
        { id: 2, label: 'ایمیل', checked: false },
        { id: 3, label: 'هر دو', checked: false },
    ]


    const singleDataTimeTourReservation = [
        { id: 1, label: '8-11 صبح', checked: false },
        { id: 2, label: '12-4 بعد از ظهر', checked: false },
        { id: 3, label: '6-10 شب', checked: false },
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Grid mb={'40px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Heading header={'فرم رزرو تور'} subHeader={'بیایید بدانیم که علاقه مند به دیدن چه چیزی هستید!'}/>
            </Grid>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={"نام و نام خانوادگی"} />
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <DatePickers header={'چه زمانی قصد بازدید دارید؟'}/>
                <Number header={'چند نفر در گروه شما هستند؟'} />
                <MultiChoice header={'به کدام تورها یا رویدادها بیشتر علاقه دارید؟'} options={MultiDataTourReservation}/>
                <MultiChoice header={'بهترین راه برای تماس با شما چیست؟'} options={MultiDataContactTourReservation} />
                <SingleChoice header={'در صورت تلفن، بهترین زمان روز برای تماس چه زمانی است؟'} options={singleDataTimeTourReservation}/>
                <LongText header={'چیز دیگری که باید بدانیم؟'}/>
                <LongText header={'و در آخر، چگونه در مورد ما شنیدید؟'}/>
            </Grid>
        </Grid>
    )
}

export default TourReservationForm