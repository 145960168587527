import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { useFormContext } from '../Context/Context';
import { useInputValues } from '../Context/InputValuesContext';
import { useErrorContext } from '../Context/ErrorContext';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const Number = ({ id , header }) => {

    const { drawerData } = useFormContext();
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);


    const { inputValues } = useInputValues();
    const { setMistake } = useErrorContext();


    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);


    const location = useLocation();
    const currentPage = location.pathname;


    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.numberValue ? drawerData[existingComponentIndex].state.numberValue : 'شماره'

    let switchSubLastName = drawerData[existingComponentIndex]?.state.subNumberValue ? drawerData[existingComponentIndex].state.subNumberValue : ''


    const [numberValue, setNumberValue] = useState(switchHeader);
    const [numberInputValue, setNumberInputValue] = useState(null);
    const [subNumberValue, setSubNumberValue] = useState(switchSubLastName);
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [error, setError] = useState('');
    const [condition, setCondition] = useState(false)



    const containerRef = useRef(null);


    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"



    const fillInputValue = () => {


        if (drawerData.length > 0) {
            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.numberValue = numberValue
                drawerData[existingComponentIndex].state.mainNumberInputValue = numberInputValue
                drawerData[existingComponentIndex].state.subNumberValue = subNumberValue
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    }

    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly



    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'Number') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const handleFindErorr = () => {
        let errorIndex = inputValues.findIndex(item => item.name === "ThisFieldCanOnlyContainNumericValues")
        if (errorIndex !== -1) {
            setError(inputValues[errorIndex].value)
        } else {
            setError('')
        }
    }



    const handleNumberChange = (event) => {
        setNumberValue(event.target.value);
    };

    const handleNumberInputChange = (event) => {
        const value = event.target.value;
        const containsNonNumber = isNaN(value);
        setNumberInputValue(value);

        if (containsNonNumber) {
            handleFindErorr();
            setMistake(true);
        } else {
            setError('')
            setMistake(false);
        }
    };

    const handleSubNumberChange = (event) => {
        setSubNumberValue(event.target.value);
    };


    // Add a global click event listener to handle clicks outside the first grid


    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };



    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, numberValue, numberInputValue, subNumberValue]);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])



    let theme = useTheme()
    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let SubHeadingInputFontSize = isUpXxl ? '14px' : '12px'
    let HeadingColor = theme.palette.blue[700]


    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused && drawerData.length > 0  && !isReadOnly ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={numberValue}
                    onChange={handleNumberChange}
                    onClick={handleClickOutside}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid>
                <input
                    type='number'
                    placeholder={'e.g., 23'}
                    style={{
                        border: '1px solid #b8bdc9',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                        marginBottom: '8px'
                    }}
                    readOnly={condition}
                    value={numberInputValue}
                    onChange={handleNumberInputChange}
                />
                {error !== '' && <Typography variant='subtitle1' color={'#FF0000'} fontWeight={700}>{error}</Typography>}
            </Grid>
            <Grid display={isReadOnly && subNumberValue === '' ? 'none':'block'}>
                {isSubHeaderVisible && (
                    <Grid className={'subHeader'} pb={'14px'}>
                        <input
                            className=""
                            placeholder={'یک عنوان فرعی وارد کنید'}
                            style={{
                                border: 'none',
                                outline: 'none',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: '100%',
                                fontSize: SubHeadingInputFontSize,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: '#57647e',
                            }}
                            readOnly={isReadOnly || condition}
                            value={subNumberValue}
                            onChange={handleSubNumberChange}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
export default Number
