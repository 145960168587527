import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const LongText = ({ id, header }) => {


    const { drawerData } = useFormContext();
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);



    const location = useLocation();
    const currentPage = location.pathname;

    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);


    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.longTextValue ? drawerData[existingComponentIndex].state.longTextValue : 'یک عنوان وارد کنید'

    let switchSubName = drawerData[existingComponentIndex]?.state.subLongTextValue ? drawerData[existingComponentIndex].state.subLongTextValue : ""


    const [longTextValue, setLongTextValue] = useState(switchHeader);
    const [textareaValue, setTextareaValue] = useState('');
    const [subLongTextValue, setSubLongTextValue] = useState(switchSubName);
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const [condition, setCondition] = useState(false)



    const containerRef = useRef(null);


    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"





    const fillInputValue = () => {

        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.longTextValue = longTextValue
                drawerData[existingComponentIndex].state.mainTextareaValue = textareaValue
                drawerData[existingComponentIndex].state.subLongTextValue = subLongTextValue
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    }

    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'LongText') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }



    const handleShortTextChange = (event) => {
        setLongTextValue(event.target.value);
    };

    const handleTextareaChange = (event) => {

        const value = event.target.value;
        if (value.length <= 400) {
            setTextareaValue(event.target.value);
            setCharCount(value.length);
        }

    };

    const handleSubShortTextChange = (event) => {
        setSubLongTextValue(event.target.value);
    };



    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, longTextValue, textareaValue, subLongTextValue]);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    let theme = useTheme()
    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let SubHeadingInputFontSize = isUpXxl ? '14px' : '12px'
    let HeadingColor = theme.palette.blue[700]


    return (

        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={longTextValue}
                    onChange={handleShortTextChange}
                    onClick={handleClickOutside}
                    onFocus={isReadOnly ? null :handleHeadingFocus}
                />
            </Grid>
            <Grid width={'100%'}>
                <textarea
                    style={{
                        resize: 'none',
                        border: '1px solid #b8bdc9',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        height: '163px',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                        marginBottom: '8px'
                    }}
                    readOnly={condition}
                    value={textareaValue}
                    onChange={handleTextareaChange}
                />
                <Typography variant='subtitle1' color={'#b8bdc9'}>
                    {charCount}/400
                </Typography>
            </Grid>
            <Grid display={isReadOnly && subLongTextValue === '' ? 'none':'block'}>
                {isSubHeaderVisible && (
                    <Grid className={'subHeader'} pb={'14px'}>
                        <input
                            className=""
                            placeholder={'یک عنوان فرعی وارد کنید'}
                            style={{
                                border: 'none',
                                outline: 'none',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: '100%',
                                fontSize: SubHeadingInputFontSize,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: '#57647e',
                            }}
                            readOnly={isReadOnly || condition}
                            value={subLongTextValue}
                            onChange={handleSubShortTextChange}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
export default LongText
