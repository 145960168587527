import { Grid, Typography } from '@mui/material'
import React from 'react'
import FullName from './FullName';
import Email from './Email';
import ShortText from './ShortText';
import LongText from './LongText';
import Phone from './Phone';
import DropDown from './DropDown';
import MultiChoice from './MultiChoice';
import DatePickers from './DatePicker';
import Heading from './Heading';

const NewHardwareRequest = () => {


    const HardwareOptions = [
        'دسکتاپ جدید',
        'لپ تاپ جدید',
        'تبلت',
    ]


    const HardwareMultiData = [
        { id: 1, label: 'Microsoft Office', checked: false },
        { id: 2, label: 'Adobe Acrobat', checked: false },
        { id: 3, label: 'Syteline', checked: false },
        { id: 4, label: 'Lotus Notes', checked: false },
        { id: 5, label: 'Doc Aces', checked: false },
        { id: 6, label: 'Google Chrome', checked: false },
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست سخت افزار جدید'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام درخواست کننده'} />
                <Email header={'ایمیل درخواست کننده'} />
                <Phone header={'شماره تلفن'} />
                <ShortText header={'بخش برای خرید'} />
                <DropDown header={'نوع کامپیوتر'} options={HardwareOptions} />
                <MultiChoice header={'نرم افزار را برای نصب انتخاب کنید'} options={HardwareMultiData} />
                <LongText header={'سایر نرم افزارها'} />
                <LongText header={'دلیل درخواست (مشخص باشد)'} />
                <DatePickers header={'تاریخ ارسال'}/>
                <DatePickers header={'تاریخ درخواست شده توسط'} />
            </Grid>
        </Grid>
    )
}

export default NewHardwareRequest