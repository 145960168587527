import { Grid, Typography } from '@mui/material'
import React from 'react'
import FullName from './FullName'
import DropDown from './DropDown'
import Email from './Email'
import ShortText from './ShortText'
import Heading from './Heading'

const ClassRegistration = () => {


    const genderDropDownData = ['آقا', 'خانم', 'N/A']


    const courseDropDownData = [
        'انگلیسی 101',
        'انگلیسی 202',
        'انگلیسی 302',
        'انگلیسی 402',
        'علوم 101',
        'علوم 201',
        'علوم 301',
        'علوم 401',
        'ریاضی 101',
        'ریاضی 202',
        'ریاضی 302',
        'ریاضی 402',
    ]


    return (

        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Grid mb={'16px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Heading header={'ثبت نام کلاس'} subHeader={'برای ثبت نام فرم را با دقت پر کنید'}/>
            </Grid>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام دانش آموز'} />
                <DropDown options={genderDropDownData} header={'جنسیت'} />
                <Email header={'ایمیل دانشجویی'} />
                <ShortText header={'شناسه دانشجویی'} />
                <DropDown options={courseDropDownData} header={'لیست کلاس ها'} />
            </Grid>
        </Grid>
    )
}

export default ClassRegistration