import React, { useEffect, useRef, useState } from 'react'
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';

const Heading = ({ id, header, subHeader }) => {

    const { drawerData } = useFormContext();


    const location = useLocation();
    const currentPage = location.pathname;

    const existingComponentIndex = drawerData.findIndex(component => component.id === id);

    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.headingValue ? drawerData[existingComponentIndex].state.headingValue : 'عنوان'

    let switchSubHeader = subHeader ? subHeader : drawerData[existingComponentIndex]?.state.subHeadingValue ? drawerData[existingComponentIndex].state.subHeadingValue : ''

    const [headingValue, setHeadingValue] = useState(switchHeader);
    const [subHeadingValue, setSubHeadingValue] = useState(switchSubHeader);
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const containerRef = useRef(null);


    const fillInputValue = () => {

        if (drawerData.length > 0) {
            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.headingValue = headingValue
                drawerData[existingComponentIndex].state.subHeadingValue = subHeadingValue
            }

        }

    }





    const handleHeadingChange = (event) => {
        const newValue = event.target.value;
        setHeadingValue(newValue);
        // updateComponentStatesById(id, { headingValue: newValue, subHeadingValue });
    };

    const handleSubHeadingChange = (event) => {
        const newValue = event.target.value;
        setSubHeadingValue(newValue);
        // updateComponentStatesById(id, { headingValue, subHeadingValue: newValue });
    };


    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, headingValue, subHeadingValue]);



    useEffect(() => {

        if (subHeader) {
            setSubHeaderVisible(true);
        }

    }, [subHeader]);



    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    let theme = useTheme()
    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let HeadingInputFontSize = isUpXxl ? '24px' : '18px'
    let SubHeadingInputFontSize = isUpXxl ? '14px' : '12px'
    let HeadingColor = theme.palette.blue[700]


    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"


    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            className={'Heading'}
            borderBottom={isHeadingFocused ? 'none' : '1px solid #ededf1'} bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '#0293e2' : 'transparent'}`}
            onClick={isReadOnly ? null : handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: HeadingInputFontSize,
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor
                }}
                    value={headingValue}
                    onChange={handleHeadingChange}
                    onClick={isReadOnly ? null : handleClickOutside}
                    readOnly={isReadOnly}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid borderBottom={'1px solid #ededf1'}>
                {
                    subHeadingValue !== '' ?
                        <input
                            className=""
                            placeholder={'یک عنوان فرعی وارد کنید'}
                            style={{
                                border: 'none',
                                outline: 'none',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: '80%',
                                fontSize: SubHeadingInputFontSize,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: '#57647e',
                            }}
                            value={subHeadingValue}
                            onChange={handleSubHeadingChange}
                            readOnly={isReadOnly}
                        />
                        :
                        subHeader && !isReadOnly ?
                            <Grid className={'subHeader'} pb={'14px'}>
                                <input
                                    className=""
                                    placeholder={'یک عنوان فرعی وارد کنید'}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        userSelect: 'text',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                        width: '80%',
                                        fontSize: SubHeadingInputFontSize,
                                        fontWeight: 500,
                                        lineHeight: 1.6,
                                        color: '#57647e',
                                    }}
                                    value={subHeadingValue}
                                    onChange={handleSubHeadingChange}
                                    readOnly={isReadOnly}
                                />
                            </Grid>
                            :
                            isSubHeaderVisible && currentPage !== '/createForm/Template' &&  !isReadOnly && (
                                <Grid className={'subHeader'} pb={'14px'}>
                                    <input
                                        className=""
                                        placeholder={'یک عنوان فرعی وارد کنید'}
                                        style={{
                                            border: 'none',
                                            outline: 'none',
                                            userSelect: 'text',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                            width: '80%',
                                            fontSize: SubHeadingInputFontSize,
                                            fontWeight: 500,
                                            lineHeight: 1.6,
                                            color: '#57647e',
                                        }}
                                        value={subHeadingValue}
                                        onChange={handleSubHeadingChange}
                                        readOnly={isReadOnly}
                                    />
                                </Grid>
                            )}
            </Grid>
        </Grid>
    )
}
export default Heading