import { Grid, Typography } from '@mui/material'
import React from 'react'

const ArchiveMessage = () => {
    return (
        <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={'150px'} width={'100%'} mx={'auto'}>
            <Grid mb={'24px'} width={'100px'} height={'100px'}>
                <img src='/assets/images/archiveMessage.svg' alt='' style={{ width: '100%', height: '100%' }} />
            </Grid>
            <Grid mb={'28px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant='h5' color={'#000'} fontWeight={700} mb={'8px'}>{'بایگانی شما خالی است!'}</Typography>
            </Grid>
        </Grid>
    )
}

export default ArchiveMessage