import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const DropDown = ({ id, options, header }) => {


    const { drawerData } = useFormContext();



    const location = useLocation();
    const currentPage = location.pathname;


    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"




    const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);


    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.dropDownValue ? drawerData[existingComponentIndex].state.dropDownValue : 'یک عنوان وارد کنید'

    let switchSubHeader = drawerData[existingComponentIndex]?.state.subDropDownValue ? drawerData[existingComponentIndex].state.subDropDownValue : ""


    let switchOption = options ?
        options
        :
        drawerData[existingComponentIndex]?.state.mainTextareaValue
            ?
            drawerData[existingComponentIndex].state.mainTextareaValue
            :
            ''

    const [dropDownValue, setDropDownValue] = useState(switchHeader);
    const [shortTextInputValue, setShortTextInputValue] = useState('لطفا انتخاب کنید');
    const [subDropDownValue, setSubDropDownValue] = useState(switchSubHeader);
    const [textareaValue, setTextareaValue] = useState(switchOption);
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [openTextArea, setOpenTextArea] = useState(false)
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredSecond, setIsHoveredSecond] = useState(false)
    const [save, setSave] = useState(false)
    const [option, setOption] = useState(null)
    const [condition, setCondition] = useState(false)


    const containerRef = useRef(null);
    const dropDown = useRef(null)
    const text = useRef(null)



    const fillInputValue = () => {

        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.dropDownValue = dropDownValue
                drawerData[existingComponentIndex].state.subDropDownValue = subDropDownValue
                drawerData[existingComponentIndex].state.mainTextareaValue = textareaValue
                drawerData[existingComponentIndex].state.mainOption = option
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    }

    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });


    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly



    let theme = useTheme()

    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));

    let SubHeadingInputFontSize = isUpXxl ? '14px' : '12px'
    let selectWidth = isUpXxl ? '310px' : '100%'

    let HeadingColor = theme.palette.blue[700]

    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'DropDown') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };


    const handleShortTextChange = (event) => {
        setDropDownValue(event.target.value);
    };

    const handleSubShortTextChange = (event) => {
        setSubDropDownValue(event.target.value);
    };


    // Add a global click event listener to handle clicks outside the first grid


    const handleHeadingFocus = (event) => {
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };



    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    const handleMouseEnterSecond = () => {
        setIsHoveredSecond(true);
    };

    const handleMouseLeaveSecond = () => {
        setIsHoveredSecond(false);
    };


    const gridStyles = {
        fontSize: '12px',
        fontWeight: 500,
        color: isHovered ? 'white' : '#636a96',
        padding: '7px 10px',
        backgroundColor: isHovered ? '#636a96' : 'white',
        borderRadius: '4px',
        transition: 'all 0.25s ease',
        border: 'none',
        textDecoration: 'underline',
        width: openTextArea ? '95px' : '100px',
        marginBottom: '8px',
        marginTop: openTextArea ? '80px' : '8px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };


    const gridStylesSecond = {
        fontSize: '12px',
        fontWeight: 500,
        color: isHoveredSecond ? 'white' : '#636a96',
        padding: '7px 10px',
        backgroundColor: isHoveredSecond ? '#636a96' : 'white',
        borderRadius: '4px',
        transition: 'all 0.25s ease',
        border: 'none',
        textDecoration: 'underline',
        width: openTextArea ? '95px' : '100px',
        marginBottom: '8px',
        marginTop: openTextArea ? '80px' : '8px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    const handleClickOutsideText = (event) => {
        if (text.current && !text.current.contains(event.target)) {
            setOpenTextArea(false);
        }
    };


    const generateOptionsFromTextarea = () => {
        if (Array.isArray(textareaValue)) {
            return textareaValue.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ));
        } else {
            return textareaValue.split('\n').map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ));
        }

    };


    const handleSaveChange = () => {
        setOpenTextArea(false)
        setSave(true)
    }


    const handleEditChange = () => {
        if (!condition) {
            setOpenTextArea(true)
            setSave(false)
        }

    }


    const handleTextareaOptionSelect = (event) => {
        const selectedOption = event.target.value;
        setOption(selectedOption);
    };



    const handleOpenTextArea = () => {
        if (!condition) {
            setOpenTextArea(!openTextArea)
        }

    }



    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        document.addEventListener('click', handleClickOutsideText);

        return () => {
            document.removeEventListener('click', handleClickOutsideText);
        };
    }, []);



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, textareaValue, option, dropDownValue, subDropDownValue]);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    useEffect(() => {
        if (options?.length > 0) {
            generateOptionsFromTextarea()
        }
    }, [])


    useEffect(() => {
        if (options?.length > 0) {
            // Set initial value of textarea based on options prop
            const initialTextAreaValue = options.join('\n');
            setTextareaValue(initialTextAreaValue);

            // You can add any additional actions you need here
            // Example: setting an initial option value
            setOption(options[0]);
        }
    }, [options]);

    console.log(save, 'parsa')





    return (
        <Grid mb={currentPage === '/build' || currentPage === 'Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={dropDownValue}
                    onChange={handleShortTextChange}
                    onClick={handleClickOutside}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid width={'fit-content'}
                ref={text}
                onClick={handleClickOutsideText}
            >
                {
                    save || isReadOnly ?
                        <select
                            style={{
                                width: selectWidth,
                                height: '40px',
                                fontSize: '14px',
                                border: '1px solid #2e69ff',
                                // boxShadow: '0 0 0 3px rgba(46,105,255,.25)',
                                color: HeadingColor,
                                borderRadius: '4px',
                                padding: '0px 10px'
                            }}
                            disabled={condition}
                            value={option}
                            onChange={handleTextareaOptionSelect}
                        >
                            {generateOptionsFromTextarea()}
                        </select>
                        :
                        <Grid
                            className={'Drop'}
                            style={{
                                position: 'relative',
                                border: '1px solid #b8bdc9',
                                borderRadius: '3px',
                                padding: '10px',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: selectWidth,
                                height: '40px',
                                color: 'HeadingColor',
                                marginBottom: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Grid width={'100%'} display={'flex'} alignItems={'center'}
                                justifyContent={'space-between'} onClick={handleOpenTextArea} style={{ cursor: 'pointer' }}>
                                <Typography variant={'h5'} fontWeight={500}>{shortTextInputValue}</Typography>
                                <KeyboardArrowDownIcon />
                            </Grid>
                            <textarea
                                style={{
                                    resize: "none",
                                    position: 'absolute',
                                    top: '37px',
                                    left: '0px',
                                    zIndex: 50,
                                    display: openTextArea ? 'block' : 'none',
                                    height: '73px',
                                    maxHeight: '73px',
                                    overflowY: 'scroll',
                                    width: selectWidth,
                                    paddingLeft: '10px',
                                    paddingTop: '10px',
                                    fontSize: '14px',
                                    border: '1px solid #2e69ff',
                                    boxShadow: '0 0 0 3px rgba(46,105,255,.25)',
                                    color: '#2c3345',
                                    borderRadius: '4px'
                                }}
                                readOnly={isReadOnly || condition}
                                value={textareaValue}
                                onChange={handleTextareaChange}
                                placeholder="گزینه اول
                        گزینه دوم
                        گزینه سوم
                        "
                            ></textarea>
                        </Grid>
                }
                <Grid display={isReadOnly ? 'none' : 'block'}>
                    {
                        openTextArea ?
                            <Grid
                                onClick={handleSaveChange}
                                style={gridStyles}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                ذخیره تغییرات
                            </Grid>
                            :
                            <Grid
                                onClick={isReadOnly || condition ? null : handleEditChange}
                                style={gridStylesSecond}
                                onMouseEnter={handleMouseEnterSecond}
                                onMouseLeave={handleMouseLeaveSecond}
                            >
                                ویرایش گزینه ها
                            </Grid>
                    }
                </Grid>
            </Grid>
            <Grid mt={'8px'} display={isReadOnly && subDropDownValue === '' ? 'none' : 'block'}>
                {isSubHeaderVisible && (
                    <Grid className={'subHeader'} pb={'14px'}>
                        <input
                            className=""
                            placeholder={'یک عنوان فرعی وارد کنید'}
                            style={{
                                border: 'none',
                                outline: 'none',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: '100%',
                                fontSize: SubHeadingInputFontSize,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: '#57647e',
                            }}
                            value={subDropDownValue}
                            onChange={handleSubShortTextChange}
                            readOnly={isReadOnly || condition}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
export default DropDown
