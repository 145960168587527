import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import DatePickers from './DatePicker'
import DropDown from './DropDown'
import Phone from './Phone'
import Address from './Address';
import Email from './Email'
import SingleChoice from './SingleChoice';
import ShortText from './ShortText'
import Appointment from './Appointment'

const OnlineDoctorAppointmentForm = () => {


    const genderOptions = [
        'آقا',
        'خانم',
    ]


    const DoctorSingleData = [
        { id: 1, label: 'بله', checked: false },
        { id: 2, label: 'نه', checked: false },
    ]


    const procedureOptions = [
        'معاینه پزشکی',
        'چک کردن توسط دکتر',
        'تجزیه و تحلیل نتایج',
        'معاینه عمومی',
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم نوبت دهی آنلاین پزشک'} subHeader={'فرم زیر را پر کنید و ما به زودی برای به روز رسانی های بیشتر و برنامه ریزی قرار ملاقات با شما تماس خواهیم گرفت.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام'} />
                <DatePickers header={'تاریخ تولد'} />
                <DropDown header={'جنسیت'} options={genderOptions} />
                <Phone header={'شماره تلفن'}/>
                <Address header={'آدرس'}/>
                <Email header={'ایمیل'} />
                <SingleChoice header={'آیا قبلاً برای تسهیلات ما درخواست داده اید؟'} options={DoctorSingleData}/>
                <ShortText header={'دوست دارید از کدام بخش وقت ملاقات بگیرید؟'} />
                <DropDown header={'برای کدام روش می خواهید وقت بگیرید؟'} options={procedureOptions}/>
                <Appointment header={"تاریخ مورد نظر برای ملاقات"}/>
            </Grid>
        </Grid>
    )
}

export default OnlineDoctorAppointmentForm