import React, { useCallback, useEffect, useState } from "react";
import Quill from "quill";
import "./basic.css";
import "highlight.js/styles/atom-one-dark.css";
import "katex/dist/katex.min.css";
import "./Table.css"; // Import quill-table CSS
import "./Paragraph.css";
import QuillTable from 'quill-table';
import { Grid } from "@mui/material";
import { useFormContext } from "../../Context/Context";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';

const Editor = ({ id }) => {
    const { drawerData } = useFormContext();

    const location = useLocation();
    const currentPage = location.pathname;
    
    const existingComponentIndex = drawerData.findIndex(
        (component) => component.id === id
    );


    console.log(drawerData[existingComponentIndex]?.state)

    let switchContent = drawerData[existingComponentIndex]?.state.mainContent ? drawerData[existingComponentIndex].state.mainContent : ""


    const [letterCount, setLetterCount] = useState(0);
    const [fontSize, setFontSize] = useState("14px");
    const [isMounted, setIsMounted] = useState(false);
    const [headerSize, setHeaderSize] = useState("1");
    const [content, setContent] = useState(switchContent);

    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.mainContent = content;
    };



    const CustomToast = ({ message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };

    const updateContent = useCallback((newContent) => {
        setContent(newContent);
    }, []);

    useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        if (isMounted) {
            const CustomList = Quill.import("formats/list");
            class EnglishAlphaList extends CustomList {
                static create(value) {
                    const node = super.create(value);
                    node.setAttribute("type", "a");
                    return node;
                }
            }
            EnglishAlphaList.blotName = "list";
            EnglishAlphaList.tagName = "OL";
            Quill.register(EnglishAlphaList, true);

            class GreekNumList extends CustomList {
                static create(value) {
                    const node = super.create(value);
                    node.setAttribute("type", "Α");
                    return node;
                }
            }
            // GreekNumList.blotName = "list";
            // GreekNumList.tagName = "OL";
            Quill.register(GreekNumList, true);
            // Register custom icons for H3 and H4 headers

            const Size = Quill.import("attributors/style/size");
            const sizes = ["8px", "10px", "12px", "14px", "18px", "24px", "36px"];
            sizes.forEach((size) => {
                Size.whitelist.push(size);
            });

            // Register the custom size format
            Quill.register(Size, true);

            const quill = new Quill("#editor", {
                readOnly: false,
                modules: {
                    syntax: false,
                    toolbar: [
                        [{ font: ["یکان"] }],
                        [{ size: sizes }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }, { list: 'check' }],
                        [{ color: [] }, { background: [] }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ header: [1, 2, 3, 4, 5, 6] }], // Add header options for H1, H2, H3, H4 , H5 , H6
                        ["blockquote", "code-block"],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }, { align: [] }],
                        ["link", "image"],
                        ["table"],
                        ["clean"],
                    ],
                },
                placeholder: "",
                theme: "snow",
                direction: "rtl",

            });

            quill.on("text-change", () => {
                const text = quill.getText();
                const count = text.trim().length;
                setLetterCount(count);
                updateContent(quill.root.innerHTML);

                if (count > 60) {
                    toast(<CustomToast message="شما از حداکثر محدودیت کاراکتر فراتر رفته اید." state={'not'} />);
                    // Disable the Quill editor
                    quill.disable();
                }
            });

            const selectHeaderSize = document.querySelector(".ql-header");
            selectHeaderSize.addEventListener("change", (event) => {
                const value = event.target.value;
                setHeaderSize(value);
                quill.format("header", parseInt(value)); // Convert value to integer for header size
            });

            const selectFontSize = document.querySelector(".ql-size");
            selectFontSize.addEventListener("change", (event) => {
                const value = event.target.value;
                setFontSize(value);
                quill.format("size", value);
            });

            const customIcons = document.querySelectorAll(".custom-icon");
            customIcons.forEach((icon) => {
                icon.style.width = "20px"; // Set custom width
                icon.style.height = "20px"; // Set custom height
            });

            // Cleanup function to destroy Quill instance when component unmounts
            return () => {
                quill.off("text-change");
            };
        }
    }, [isMounted, updateContent]); // Empty dependency array ensures useEffect runs only once on component mount

    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, content]);

    return (
        <Grid mb={'30px'}>
            <Grid id="editor"></Grid>
            <Grid p={"8px"} bgcolor={"#050c34"} color={"white"} fontSize={"12px"}>
                {letterCount}/60000
            </Grid>
        </Grid>
    );
};

export default Editor;
