import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import NewCustomerCard from '../Component/NewCustomerCard';
import ITServiceTicket from '../Component/ITServiceTicket';
import CustomSimpleOrderForm from '../Component/CustomSimpleOrderForm';
import CourseRegistrationForm from '../Component/CourseRegistrationForm';
import ClassRegistration from '../Component/ClassRegistration';
import BusinessRegistrationForm from '../Component/BusinessRegistrationForm';
import NewHardwareRequest from '../Component/NewHardwareRequest';
import FootballRegistrationForm from '../Component/FootballRegistrationForm';
import TourReservationForm from '../Component/TourReservationForm';
import HomeLoanApplication from '../Component/HomeLoanApplication';
import SelfEvaluationTemplate from '../Component/SelfEvaluationTemplate';
import EmployeeRegistrationForm from '../Component/EmployeeRegistrationForm';
import StudentRepresentativeNominationForm from '../Component/StudentRepresentativeNominationForm';
import OnlineDoctorAppointmentForm from '../Component/OnlineDoctorAppointmentForm';




const cardTemplateData = [
    {
        id: 1,
        title: 'فرم ثبت نام مشتری جدید',
        src: '/assets/images/NewCustomerCard.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم ثبت نام مشتری جدید',
                    subHeadingValue: 'جزئیات مشتری:'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "چطور در مورد ما شنیدید؟",
                    subDropDownValue: "",
                    option: ['روزنامه', 'اینترنت', 'مجله', 'دیگر'],
                    textareaValue: "",
                    mainTextareaValue: ['روزنامه', 'اینترنت', 'مجله', 'دیگر'],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "بازخورد درباره ما:",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "پیشنهاداتی در صورت وجود برای بهبود بیشتر:",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: "آیا حاضرید به ما توصیه ای کنید؟",
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "شاید",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
        ],
        modalContent: <NewCustomerCard />
    },
    {
        id: 2,
        title: 'فرم بلیط خدمات فناوری اطلاعات',
        src: '/assets/images/ItService.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'بلیط خدمات فناوری اطلاعات',
                    subHeadingValue: 'لطفا جزئیات مشکل را ارائه دهید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "بخش",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شناسه کامپیوتر",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: "آپلود فایل",
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "مشکل را شرح دهید",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <ITServiceTicket />
    },
    {
        id: 3,
        title: 'فرم سفارش ساده سفارشی',
        src: '/assets/images/CustomSimpleOrderForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'سفارش خود را در اینجا ثبت کنید',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ProductList",
                state:
                {
                    products: [],
                    productValue: "لیست محصولات",
                    totalPrice: 10000,
                    totalQuantity: 1,
                    mainProducts: [
                        {
                            id: 608,
                            name: "نام محصولات",
                            description: "توضیحات را وارد کنید",
                            image: null,
                            price: "10000",
                            quantity: 1
                        }
                    ],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "یک عنوان وارد کنید",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "یک عنوان وارد کنید",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس تحویل",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "دستورالعمل های ویژه تحویل",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <CustomSimpleOrderForm />
    },
    {
        id: 4,
        title: 'فرم ثبت نام دوره',
        src: '/assets/images/course.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم ثبت نام',
                    subHeadingValue: 'برای ثبت نام فرم را با دقت پر کنید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام دانش آموز",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ تولد",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "جنسیت",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: ['آقا', 'خانم', 'N/A'],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل دانشجویی",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره موبایل",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره کاری",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شرکت",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "دوره ها",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        'Windows 8',
                        'مقدمه ای بر لینوکس',
                        'انگلیسی 101',
                        'انگلیسی 102',
                        'نوشتن خلاق 1',
                        'نوشتن خلاق 2',
                        'تاریخ 101',
                        'تاریخ 102',
                        'ریاضی 101',
                        'ریاضی 102'
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "نظرات اضافی",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <CourseRegistrationForm />
    },
    {
        id: 5,
        title: 'ثبت نام کلاس',
        src: '/assets/images/ClassRegistration.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'ثبت نام کلاس',
                    subHeadingValue: 'برای ثبت نام فرم را با دقت پر کنید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام دانش آموز",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "جنسیت",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: ['آقا', 'خانم', 'N/A'],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل دانشجویی",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شناسه دانشجویی",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "لیست کلاس ها",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        'انگلیسی 101',
                        'انگلیسی 202',
                        'انگلیسی 302',
                        'انگلیسی 402',
                        'علوم 101',
                        'علوم 201',
                        'علوم 301',
                        'علوم 401',
                        'ریاضی 101',
                        'ریاضی 202',
                        'ریاضی 302',
                        'ریاضی 402',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
        ],
        modalContent: <ClassRegistration />
    },
    {
        id: 6,
        title: 'فرم ثبت نام تجاری',
        src: '/assets/images/BusinessRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'کسب و کار خود را ثبت کنید',
                    subHeadingValue: 'لطفا تمام مشخصات مورد نیاز برای ثبت کسب و کار خود را با ما ارائه دهید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "صاحب کسب و کار",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "نام کسب و کار",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تماس",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "نوع کسب و کار",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        'فروشگاه/کافه',
                        'وام دادن',
                        'فروشگاه',
                        'اجاره ها',
                        'بقیه را لطفا در زیر مشخص کنید.',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "پیام",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <BusinessRegistrationForm />
    },
    {
        id: 7,
        title: 'درخواست سخت افزار جدید',
        src: '/assets/images/HardwareRequest.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست سخت افزار جدید',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام درخواست کننده",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل درخواست کننده",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "بخش برای خرید",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "نوع کامپیوتر",
                    subDropDownValue: "",
                    option: [
                        'دسکتاپ جدید',
                        'لپ تاپ جدید',
                        'تبلت',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'دسکتاپ جدید',
                        'لپ تاپ جدید',
                        'تبلت',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: "نرم افزار را برای نصب انتخاب کنید",
                    multiData: [
                        {
                            id: 1, label: 'Microsoft Office', checked: false
                        },
                        {
                            id: 2, label: 'Adobe Acrobat', checked: false
                        },
                        {
                            id: 3, label: 'Syteline', checked: false
                        },
                        {
                            id: 4, label: 'Lotus Notes', checked: false
                        },
                        {
                            id: 5, label: 'Doc Aces', checked: false
                        },
                        {
                            id: 6, label: 'Google Chrome', checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "سایر نرم افزارها",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "دلیل درخواست (مشخص باشد)",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ ارسال",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ درخواست ",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
        ],
        modalContent: <NewHardwareRequest />
    },
    {
        id: 8,
        title: 'فرم ثبت نام فوتبال',
        src: '/assets/images/FootballRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم ثبت نام فوتبال',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام بازیکن',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ تولد',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس منزل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'جنسیت',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "شاید",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'لطفا عکس خود را آپلود کنید',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل بازیکن',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'گروه تیم بازیکنان',
                    subDropDownValue: "",
                    option: [
                        'مهد کودک',
                        'راهنمایی',
                        'دبیرستان',
                        'دانشگاه',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'مهد کودک',
                        'راهنمایی',
                        'دبیرستان',
                        'دانشگاه',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'حمله یا دفاع',
                    singleData: [
                        {
                            id: 1,
                            label: 'حمله',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'دفاع',
                            checked: false
                        },
                        {
                            id: 3,
                            label: "نمی دانم",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'قد',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'وزن',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "نظرات",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <FootballRegistrationForm />
    },
    {
        id: 9,
        title: 'فرم رزرو تور',
        src: '/assets/images/TourReservationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم رزرو تور',
                    subHeadingValue: 'بیایید بدانیم که علاقه مند به دیدن چه چیزی هستید!'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'چه زمانی قصد بازدید دارید؟',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: "شماره",
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'به کدام تورها یا رویدادها بیشتر علاقه دارید؟',
                    multiData: [
                        { id: 1, label: 'انقلاب تازه شروع بود', checked: false },
                        { id: 2, label: 'ماورایی', checked: false },
                        { id: 3, label: 'سنت', checked: false },
                        { id: 5, label: 'دروازه‌ها، انگورها و قبرستان‌ها', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'بهترین راه برای تماس با شما چیست؟',
                    multiData: [
                        { id: 1, label: 'تلفن', checked: false },
                        { id: 2, label: 'ایمیل', checked: false },
                        { id: 3, label: 'هر دو', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'در صورت تلفن، بهترین زمان روز برای تماس چه زمانی است؟',
                    singleData: [
                        { id: 1, label: '8-11 صبح', checked: false },
                        { id: 2, label: '12-4 بعد از ظهر', checked: false },
                        { id: 3, label: '6-10 شب', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "چیز دیگری که باید بدانیم؟",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'و در آخر، چگونه در مورد ما شنیدید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <TourReservationForm />
    },
    {
        id: 10,
        title: 'درخواست وام مسکن',
        src: '/assets/images/HomeLoanApplication.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست وام مسکن',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا شما صاحب خانه هستید یا اجاره می دهید؟',
                    singleData: [
                        { id: 1, label: 'صاحب خانه', checked: false },
                        { id: 2, label: 'اجاره', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا قبل از خرید خانه جدید باید خانه خود را بفروشید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'خیر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع خرید',
                    subDropDownValue: "",
                    option: [
                        'اقامتگاه اولیه',
                        'اقامتگاه ثانویه',
                        'سرمایه گذاری',
                        'مطمئن نیستم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'اقامتگاه اولیه',
                        'اقامتگاه ثانویه',
                        'سرمایه گذاری',
                        'مطمئن نیستم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا از قبل واجد شرایط وام هستید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'خیر', checked: false },
                        { id: 3, label: 'خریدار نقدی', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا شما توسط یک مشاور املاک نمایندگی دارید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'خیر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظرات یا سوالات:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <HomeLoanApplication />
    },
    {
        id: 11,
        title: 'الگوی خود ارزیابی',
        src: '/assets/images/SelfEvaluationTemplate.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'الگوی خود ارزیابی',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'بخش',
                    subDropDownValue: "",
                    option: [
                        'اداره',
                        'امور مالی',
                        'توسعه',
                        'منابع انسانی',
                        'IT',
                        'فروش',
                        'بازاریابی',
                        'تولید',
                    ],
                    textareaValue: [
                        'اداره',
                        'امور مالی',
                        'توسعه',
                        'منابع انسانی',
                        'IT',
                        'فروش',
                        'بازاریابی',
                        'تولید',
                    ],
                    mainTextareaValue: [
                        'اداره',
                        'امور مالی',
                        'توسعه',
                        'منابع انسانی',
                        'IT',
                        'فروش',
                        'بازاریابی',
                        'تولید',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'عنوان شغلی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'لطفا آخرین تاریخ ارزیابی خود را انتخاب کنید.',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '1. مهمترین دستاوردهای مرتبط با موقعیت خود را از دوره قبل به ترتیب اهمیت تعریف کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '2. بزرگترین چالش های خود را تعریف کنید و چگونه آنها را در این مدت حل کردید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '3. حوزه هایی را که فکر می کنید باید بهبود ببخشید و برنامه بهبود خود را تعریف کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '4. بیان کنید که در طول انجام وظایف خود از چه جنبه هایی بیشترین و کمترین لذت را می برید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '5. وظایفم را در بازه زمانی از پیش تعیین شده انجام دادم.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '6. هیچ وقت در طول دوره هیچ مهلتی را از دست نمی دهم.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '7. من همیشه با سایر اعضای تیم به وضوح ارتباط برقرار می کنم.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '8. من به جای اینکه در طول پروژه ها واکنشی باشم، فعال هستم.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '9. من به تمام اهدافم که در ارزیابی قبلی تعریف کرده ام دست یافته ام.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '10. اهداف زیر را که می خواهید تا تاریخ ارزیابی بعدی به آن برسید مشخص کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '11. بیان کنید که چگونه مدیرتان می تواند به شما کمک کند تا رضایت شما را افزایش دهید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <SelfEvaluationTemplate />
    },
    {
        id: 12,
        title: 'فرم ثبت نام کارمندان',
        src: '/assets/images/EmployeeRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'الگوی خود ارزیابی',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ تولد',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'درخواست برای موقعیت :',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'نوع کار',
                    singleData: [
                        { id: 1, label: 'دائمی', checked: false },
                        { id: 2, label: 'موقت', checked: false },
                        { id: 2, label: 'هر دو', checked: false },
                        { id: 2, label: 'دیگر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'لطفا رزومه را آپلود کنید',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'اطلاعات تکمیلی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'وضعیت جانبازی (لطفاً گزینه ای را انتخاب کنید که وضعیت شما را به بهترین شکل توصیف می کند)',
                    singleData: [
                        { id: 1, label: 'من یک جانباز هستم', checked: false },
                        { id: 2, label: 'من یک جانباز معلول ویژه هستم', checked: false },
                        { id: 3, label: "من یک جانباز اخیرا جدا شده ام", checked: false },
                        { id: 4, label: "من یک جانباز حفاظت شده دیگر هستم", checked: false },
                        { id: 5, label: "من جانباز نیستم", checked: false },
                        { id: 6, label: "ترجیح میدهم پاسخ ندهم ", checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'جنسیت',
                    singleData: [
                        { id: 1, label: 'زن', checked: false },
                        { id: 2, label: 'مرد', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'معلولیت ها',
                    singleData: [
                        { id: 1, label: 'من هیچ معلولیتی ندارم', checked: false },
                        { id: 2, label: 'من دو یا چند اختلال و/یا شرایط پزشکی ناتوان کننده دارم.', checked: false },
                        { id: 3, label: 'من یک مشکل یادگیری خاص دارم (مثلاً نارساخوانی/دیسپراکسی/AD(H)D', checked: false },
                        { id: 4, label: 'من یک اختلال اجتماعی / ارتباطی مانند سندرم آسپرگر / سایر اختلالات طیف اوتیسم دارم', checked: false },
                        { id: 5, label: 'من یک بیماری طولانی مدت یا وضعیت سلامتی مانند سرطان، HIV، دیابت، بیماری مزمن قلبی یا صرع دارم.', checked: false },
                        { id: 6, label: 'من یک نقص جسمی یا مشکلات حرکتی دارم (مثلاً مشکل در استفاده از بازوها/استفاده از ویلچر یا عصا)', checked: false },
                        { id: 7, label: 'من ناشنوا هستم یا اختلال شنوایی جدی دارم', checked: false },
                        { id: 8, label: 'من نابینا هستم یا یک نقص بینایی جدی دارم که با عینک اصلاح نشده است', checked: false },
                        { id: 9, label: 'ترجیح می دهید پاسخ ندهید', checked: false },
                        { id: 10, label: 'من یک معلولیت، نقص یا وضعیت پزشکی دارم که در اینجا ذکر نشده است', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'نژاد',
                    singleData: [
                        { id: 1, label: 'بومی آمریکایی یا بومی آلاسکا', checked: false },
                        { id: 2, label: 'آسیایی', checked: false },
                        { id: 3, label: 'سیاه پوست یا آفریقایی آمریکایی', checked: false },
                        { id: 4, label: 'بومی هاوایی یا سایر ساکنان جزایر اقیانوس آرام', checked: false },
                        { id: 5, label: 'سفید پوست', checked: false },
                        { id: 6, label: 'دو یا چند نژاد', checked: false },
                        { id: 7, label: 'دیگر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'یادداشت های اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <EmployeeRegistrationForm />
    },
    {
        id: 13,
        title: 'فرم نامزدی نماینده دانشجو',
        src: '/assets/images/StudentRepresentativeNominationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم نامزدی نماینده دانشجو',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام شما',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'شناسه دانشجویی',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'کلاس',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'آدرس ایمیل مدرسه',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تماس',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'به نامی که می خواهید او نماینده دانشجو باشد رای دهید.',
                    subDropDownValue: "",
                    option: [
                        'دانش آموز اول',
                        'دانش آموز دوم',
                        'دانش آموز سوم',
                        'دانش آموز چهارم',
                        'دانش آموز پنجم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'دانش آموز اول',
                        'دانش آموز دوم',
                        'دانش آموز سوم',
                        'دانش آموز چهارم',
                        'دانش آموز پنجم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'به نماینده معاون دانشجویی رای دهید.',
                    subDropDownValue: "",
                    option: [
                        'دانش آموز اول',
                        'دانش آموز دوم',
                        'دانش آموز سوم',
                        'دانش آموز چهارم',
                        'دانش آموز پنجم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'دانش آموز اول',
                        'دانش آموز دوم',
                        'دانش آموز سوم',
                        'دانش آموز چهارم',
                        'دانش آموز پنجم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'برای نامزدی خود دلیل بیاورید',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Captcha',
                state: {}
            },
        ],
        modalContent: <StudentRepresentativeNominationForm />
    },
    {
        id: 14,
        title: 'فرم نوبت دهی آنلاین پزشک',
        src: '/assets/images/OnlineDoctorAppointmentForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم نوبت دهی آنلاین پزشک',
                    subHeadingValue: 'فرم زیر را پر کنید و ما به زودی برای به روز رسانی های بیشتر و برنامه ریزی قرار ملاقات با شما تماس خواهیم گرفت.'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ تولد",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'به نامی که می خواهید او نماینده دانشجو باشد رای دهید.',
                    subDropDownValue: "",
                    option: [
                        'آقا',
                        'خانم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'آقا',
                        'خانم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'آدرس ایمیل مدرسه',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا قبلاً برای تسهیلات ما درخواست داده اید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'نه', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'دوست دارید از کدام بخش وقت ملاقات بگیرید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'برای کدام روش می خواهید وقت بگیرید؟',
                    subDropDownValue: "",
                    option: [
                        'معاینه پزشکی',
                        'چک کردن توسط دکتر',
                        'تجزیه و تحلیل نتایج',
                        'معاینه عمومی',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'معاینه پزشکی',
                        'چک کردن توسط دکتر',
                        'تجزیه و تحلیل نتایج',
                        'معاینه عمومی',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: "تاریخ مورد نظر برای ملاقات",
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },
        ],
        modalContent: <OnlineDoctorAppointmentForm />
    },
]


export default cardTemplateData;