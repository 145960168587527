import React, { createContext, useState } from 'react';

// Create the context
export const DrawerContext = createContext();

// Create a provider component
export const DrawerProvider = ({ children }) => {
    const [selectDrawerItem, setSelectDrawerItem] = useState('AllForm');



    return (
        <DrawerContext.Provider value={{ selectDrawerItem, setSelectDrawerItem }}>
            {children}
        </DrawerContext.Provider>
    );
};
