import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const ShortText = ({ id, header }) => {


    const { drawerData } = useFormContext();
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);


    const location = useLocation();
    const currentPage = location.pathname;


    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.shortTextValue ? drawerData[existingComponentIndex].state.shortTextValue : "یک عنوان وارد کنید"
    let switchSubHeader = drawerData[existingComponentIndex]?.state.subShortTextValue ? drawerData[existingComponentIndex].state.subShortTextValue : ""


    const [shortTextValue, setShortTextValue] = useState(switchHeader);
    const [shortTextInputValue, setShortTextInputValue] = useState("");
    const [subShortTextValue, setSubShortTextValue] = useState(switchSubHeader);
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const [condition, setCondition] = useState(false)


    const containerRef = useRef(null);


    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"




    const fillInputValue = () => {

        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {

                drawerData[existingComponentIndex].state.shortTextValue = shortTextValue
                drawerData[existingComponentIndex].state.mainShortTextInputValue = shortTextInputValue
                drawerData[existingComponentIndex].state.subShortTextValue = subShortTextValue
                drawerData[existingComponentIndex].state.readOnly = readOnly

            }

        }

    }

    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly



    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'ShortText') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }

    const handleShortTextChange = (event) => {
        setShortTextValue(event.target.value);
    };

    const handleShortTextInputChange = (event) => {
        const value = event.target.value;
        if (value.length <= 100) {
            setShortTextInputValue(value);
            setCharCount(value.length);
        }
    };

    const handleSubShortTextChange = (event) => {
        setSubShortTextValue(event.target.value);
    };


    const handleHeadingFocus = (event) => {
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    let theme = useTheme()

    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let SubHeadingInputFontSize = isUpXxl ? '14px' : '12px'

    let HeadingColor = theme.palette.blue[700]


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, shortTextInputValue, shortTextValue, subShortTextValue]);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])



    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={shortTextValue}
                    onChange={handleShortTextChange}
                    onClick={handleClickOutside}
                    onFocus={isReadOnly ? null :handleHeadingFocus}
                />
            </Grid>
            <Grid>
                <input
                    style={{
                        border: '1px solid #b8bdc9',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                        marginBottom: '8px'
                    }}
                    readOnly={condition}
                    value={shortTextInputValue}
                    onChange={handleShortTextInputChange}
                />
                <Typography variant='subtitle1' color={'#b8bdc9'}>
                    {charCount}/100
                </Typography>
            </Grid>
            <Grid display={isReadOnly && subShortTextValue === '' ? 'none':'block'}>
                {isSubHeaderVisible && (
                    <Grid className={'subHeader'} pb={'14px'}>
                        <input
                            className=""
                            placeholder={'یک عنوان فرعی وارد کنید'}
                            style={{
                                border: 'none',
                                outline: 'none',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: '100%',
                                fontSize: SubHeadingInputFontSize,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: '#57647e',
                            }}
                            readOnly={isReadOnly || condition}
                            value={subShortTextValue}
                            onChange={handleSubShortTextChange}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
export default ShortText
