import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import DropDown from './DropDown';
import ShortText from './ShortText';
import DatePickers from './DatePicker';
import LongText from './LongText';
import ScaleRating from './ScaleRating';

const SelfEvaluationTemplate = () => {


    const SelfDropDownOptions = [
        'اداره',
        'امور مالی',
        'توسعه',
        'منابع انسانی',
        'IT',
        'فروش',
        'بازاریابی',
        'تولید',
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Grid mb={'40px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Heading header={'الگوی خود ارزیابی'} />
            </Grid>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={"نام"} />
                <DropDown header={'بخش'} options={SelfDropDownOptions}/>
                <ShortText header={'عنوان شغلی'} />
                <DatePickers header={'لطفا آخرین تاریخ ارزیابی خود را انتخاب کنید.'} />
                <LongText header={'1. مهمترین دستاوردهای مرتبط با موقعیت خود را از دوره قبل به ترتیب اهمیت تعریف کنید.'} />
                <LongText header={'2. بزرگترین چالش های خود را تعریف کنید و چگونه آنها را در این مدت حل کردید.'} />
                <LongText header={'3. حوزه هایی را که فکر می کنید باید بهبود ببخشید و برنامه بهبود خود را تعریف کنید.'} />
                <LongText header={'4. بیان کنید که در طول انجام وظایف خود از چه جنبه هایی بیشترین و کمترین لذت را می برید.'} />
                <ScaleRating header={'5. وظایفم را در بازه زمانی از پیش تعیین شده انجام دادم.'} bestTitle={'موافقم'} worthTitle={'مخالفم'}/>
                <ScaleRating header={'6. هیچ وقت در طول دوره هیچ مهلتی را از دست نمی دهم.'} bestTitle={'موافقم'} worthTitle={'مخالفم'}/>
                <ScaleRating header={'7. من همیشه با سایر اعضای تیم به وضوح ارتباط برقرار می کنم.'} bestTitle={'موافقم'} worthTitle={'مخالفم'}/>
                <ScaleRating header={'8. من به جای اینکه در طول پروژه ها واکنشی باشم، فعال هستم.'} bestTitle={'موافقم'} worthTitle={'مخالفم'}/>
                <ScaleRating header={'9. من به تمام اهدافم که در ارزیابی قبلی تعریف کرده ام دست یافته ام.'} bestTitle={'موافقم'} worthTitle={'مخالفم'}/>
                <LongText header={'10. اهداف زیر را که می خواهید تا تاریخ ارزیابی بعدی به آن برسید مشخص کنید.'} />
                <LongText header={'11. بیان کنید که چگونه مدیرتان می تواند به شما کمک کند تا رضایت شما را افزایش دهید.'} />
            </Grid>
        </Grid>
    )
}

export default SelfEvaluationTemplate