import { Grid, Typography } from '@mui/material'
import React from 'react'

const TrashMessage = () => {
    return (
        <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={'150px'} width={'100%'} mx={'auto'}>
            <Grid mb={'24px'} width={'100px'} height={'100px'}>
                <img src='/assets/images/trashMessage.svg' alt='' style={{width:'100%' , height:'100%'}}/>
            </Grid>
            <Grid mb={'28px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant='h5' color={'#000'} fontWeight={700} mb={'8px'}>{'سطل زباله شما خالی است!'}</Typography>
            </Grid>
        </Grid>
    )
}

export default TrashMessage