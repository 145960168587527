import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import Number from './Number';
import ShortText from './ShortText';
import Email from './Email';
import Phone from './Phone';
import DropDown from './DropDown';
import LongText from './LongText';
import Captcha from './Captcha';

const StudentRepresentativeNominationForm = () => {



    const studentOptions = [
        'دانش آموز اول',
        'دانش آموز دوم',
        'دانش آموز سوم',
        'دانش آموز چهارم',
        'دانش آموز پنجم',
    ]



    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم نامزدی نماینده دانشجو'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام شما'} />
                <Number header={'شناسه دانشجویی'} />
                <ShortText header={'کلاس'} />
                <Email header={'آدرس ایمیل مدرسه'} />
                <Phone header={'شماره تماس'} />
                <DropDown header={'به نامی که می خواهید او نماینده دانشجو باشد رای دهید.'} options={studentOptions} />
                <DropDown header={'به نماینده معاون دانشجویی رای دهید.'} options={studentOptions} />
                <LongText header={'برای نامزدی خود دلیل بیاورید'} />
                <Captcha header={'لطفا بررسی کنید که انسان هستید.'}/>
            </Grid>
        </Grid>
    )
}

export default StudentRepresentativeNominationForm