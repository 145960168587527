import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid } from "@mui/material";
import moment from 'moment-jalaali';
import DatePicker from 'react-datepicker2';

import '../App.css'


import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';



const DatePickers = ({ id, header }) => {

    const { drawerData } = useFormContext();
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);


    const location = useLocation();
    const currentPage = location.pathname;

    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.dateValue ? drawerData[existingComponentIndex].state.dateValue : "تقویم"

    let switchSubHeader = drawerData[existingComponentIndex]?.state.subHeader ? drawerData[existingComponentIndex].state.subHeader : ""

    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"
    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly



    const [date, setDate] = useState(null);
    const [dateValue, setDateValue] = useState(switchHeader);
    const [subHeader, setSubHeader] = useState(switchSubHeader);
    const [border, setBorder] = useState(false)
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [condition, setCondition] = useState(false)

    const gridRef = useRef(null);




    const fillInputValue = () => {
        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.mainDate = moment(date).format('jYYYY/jMM/jDD')
                drawerData[existingComponentIndex].state.dateValue = dateValue
                drawerData[existingComponentIndex].state.subHeader = subHeader
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }
    }


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === "DatePicker") {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const handleDateChange = (value) => {
        if (!isReadOnly) {
            setDate(value); // Update date only if not in read-only mode
        }
    };


    const handleHeaderDateChange = (event) => {
        setDateValue(event.target.value);
    };

    const handleSubHeaderDateChange = (event) => {
        setSubHeader(event.target.value);
    };

    const handleOutsideClick = (event) => {
        if (gridRef.current && !gridRef.current.contains(event.target)) {
            setBorder(false);
        }
    };

    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, date, dateValue, subHeader]);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    return (

        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={gridRef}
            onClick={() => {
                setBorder(true)
            }} border={border && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '2px solid #0293e2' : '2px solid transparent'}>
            <Grid pb={'16px'}>
                <input
                    className="customHead-input"
                    placeholder={'یک عنوان وارد کنید'}
                    onChange={handleHeaderDateChange}
                    value={dateValue}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                    readOnly={isReadOnly || condition}
                />
            </Grid>
            <Grid pb={'8px'}>
                <Grid className="custom-input-wrapper">
                    {
                        condition ? null : (
                            <Grid width={'100%'} style={{cursor:'pointer'}}>
                                <DatePicker
                                    value={date}
                                    onChange={handleDateChange} // Corrected line
                                    isGregorian={false} // Set to false for Persian date
                                    timePicker={false} // Disable time picker
                                    className="custom-input"
                                />
                            </Grid>
                        )
                    }

                    <CalendarMonthIcon className={'iconDatePicker'} />
                </Grid>
            </Grid>
            <Grid>
                <input
                    onChange={handleSubHeaderDateChange}
                    value={subHeader}
                    className="custom-inputs"
                    readOnly={isReadOnly || condition}
                    placeholder={'یک SubLabel وارد کنید'}
                    style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: '#b8bdc9',
                    }}
                />
            </Grid>
        </Grid>

    )
}
export default DatePickers
