import React, { useEffect, useRef, useState } from 'react'
import { Grid } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';

const Image = ({ id }) => {


    const { drawerData } = useFormContext();


    const location = useLocation();
    const currentPage = location.pathname;

    const existingComponentIndex = drawerData.findIndex(component => component.id === id);


    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [image, setImage] = useState(null);
    const containerRefs = useRef(null);


    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"





    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.mainImage = image
    }




    const handleImageChange = (e) => {


        if (currentPage === '/Publish') {
            const file = e.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = () => {
                    setImage(reader.result);
                };

                reader.readAsDataURL(file);
            }
        }

    };



    const handleClickOutside = (event) => {
        if (containerRefs.current && !containerRefs.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };




    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, image]);





    return (
        <Grid border={`2px solid ${isHeadingFocused  && !isReadOnly ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside} 
            mb={currentPage === '/build'|| currentPage === '/Publish' ? '28px' : '50px'} 
            p={currentPage === '/build'|| currentPage === '/Publish' ? '12px 10px' : '24px 36px'} borderRadius={'3px'} 
            mx={currentPage === '/build'|| currentPage === '/Publish' ? '4px' : '0px'} ref={containerRefs}
            display={'flex'} alignItems={'center'} justifyContent={'center'}
        >
            {
                isReadOnly || !isReadOnly ?
                    <Grid width={'120px'} height={'100px'}>
                        <label htmlFor="imageUpload" style={{ cursor: 'pointer' }}>
                            <img src={image || '/assets/images/image_placeholder.png'} alt="" style={{ width: '120px', height: '100px' }} />
                        </label>
                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                    </Grid>
                    :
                    <Grid width={'120px'} height={'100px'}>
                        <label style={{ cursor: 'pointer' }}>
                            <img src={image || '/assets/images/image_placeholder.png'} alt="" style={{ width: '120px', height: '100px' }} />
                        </label>
                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                    </Grid>
            }

        </Grid>
    )
}
export default Image
