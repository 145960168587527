import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the Context
const FormLimitContext = createContext();

// Custom hook to use the FormLimitContext
export const useFormLimit = () => useContext(FormLimitContext);

// Provider component
export const FormLimitProvider = ({ children }) => {

    const [formCount, setFormCount] = useState(0);
    const formLimit = 5;
    const [canCreateForm, setCanCreateForm] = useState(true);

    // Update canCreateForm whenever formCount changes
    useEffect(() => {
        setCanCreateForm(formCount < formLimit);
    }, [formCount]);

    // Function to increment form count
    const incrementFormCount = () => {
        setFormCount(prev => prev + 1);
    };

    // Function to decrement form count
    const decrementFormCount = () => {
        setFormCount(prev => (prev > 0 ? prev - 1 : 0));
    };

    return (
        <FormLimitContext.Provider value={{
            formCount,
            formLimit,
            canCreateForm,
            incrementFormCount,
            decrementFormCount
        }}>
            {children}
        </FormLimitContext.Provider>
    );
};
