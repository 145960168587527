import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const ProductList = ({ id }) => {

    const { drawerData } = useFormContext();
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);


    const location = useLocation();
    const currentPage = location.pathname;


    const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"

    let switchHeader = drawerData[existingComponentIndex]?.state.productValue ? drawerData[existingComponentIndex].state.productValue : 'لیست محصولات'

    let switchTotalPrice = drawerData[existingComponentIndex]?.state.totalPriceText ? drawerData[existingComponentIndex].state.totalPriceText : 'کل'

    let switchProduct = drawerData[existingComponentIndex]?.state.mainProducts ? drawerData[existingComponentIndex].state.mainProducts : [
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
    ]

    // let switchSubName = drawerData[existingComponentIndex]?.state.firstNameValue ? drawerData[existingComponentIndex].state.firstNameValue : "نام"

    // let switchSubName = drawerData[existingComponentIndex]?.state.firstNameValue ? drawerData[existingComponentIndex].state.firstNameValue : "نام"




    // state
    const [condition, setCondition] = useState(false)
    const [productValue, setProductValue] = useState(switchHeader);
    const [border, setBorder] = useState(false);
    const [show, setShow] = useState(false)
    const [totalPriceText, setTotalPriceText] = useState(switchTotalPrice);
    const [count, setCount] = useState(0)
    const [products, setProducts] = useState(switchProduct);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [productId, setProductId] = useState(false)

    //ref
    const gridRef = useRef(null);
    const productRefs = useRef(Array(products.length).fill(null).map(() => createRef()));


    const fillInputValue = () => {
        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.mainProducts = products
                drawerData[existingComponentIndex].state.productValue = productValue
                drawerData[existingComponentIndex].state.totalPrice = totalPrice
                drawerData[existingComponentIndex].state.totalQuantity = totalQuantity
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    }

    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly

    //style
    const theme = useTheme();
    let HeadingColor = theme.palette.blue[700];


    //function


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'ProductList') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const convertToPersianNumber = (number) => {
        const persianDigits = [
            '۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'
        ];

        return String(number).replace(/\d/g, (digit) => persianDigits[digit]);
    };

    const handlePhoneChange = (event) => {
        setProductValue(event.target.value);
    };


    const handleProduct = () => {

        setCount(count + 1);
        const newProduct = {
            id: count,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        };

        setProducts((prevProducts) => [...prevProducts, newProduct]);


    };


    const handleTotalPrice = (event) => {
        setTotalPriceText(event.target.value)
    }


    const handleNameChange = (event, productId) => {

        const updatedProducts = products.map((product) =>
            product.id === productId ? { ...product, name: event.target.value } : product
        );
        setProducts(updatedProducts);

    };

    const handleDescriptionChange = (event, productId) => {
        const updatedProducts = products.map((product) =>
            product.id === productId ? { ...product, description: event.target.value } : product
        );
        setProducts(updatedProducts);
    };

    const handleImageUpload = (event, productId) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();
            reader.onload = () => {
                const updatedProducts = products.map((product) =>
                    product.id === productId ? { ...product, image: reader.result } : product
                );
                setProducts(updatedProducts);
            };
            reader.readAsDataURL(selectedImage);
        }
    };

    const handlePriceChange = (event, productId) => {
        const updatedProducts = products.map((product) =>
            product.id === productId ? { ...product, price: event.target.value } : product
        );
        setProducts(updatedProducts);
    };

    const handleSelectChange = (event, productId) => {
        const updatedProducts = products.map((product) =>
            product.id === productId ? { ...product, quantity: Number(event.target.value) } : product
        );
        setProducts(updatedProducts);
    };


    const handleHeadingFocus = (event) => {
        event.target.select();
    };

    const handleOutsideClick = (event) => {
        if (gridRef.current && !gridRef.current.contains(event.target)) {
            setBorder(false);
        }
    };


    const handleOutsideClickShow = (event) => {
        const clickedOutside = productRefs.current.every((ref) => {
            return ref && !ref.current.contains(event.target);
        });

        if (clickedOutside) {
            setShow(false);
            setSelectedProductId(null);
        }
    };


    const handleDeleteProduct = (productId) => {
        const updatedProducts = products.filter((product) => product.id !== productId);
        setProducts(updatedProducts);
    };


    const handleDeleteIconClick = (event, productId) => {
        event.stopPropagation();
        handleDeleteProduct(productId);
    };


    const handleSetId = (id) => {
        if (condition) {
            setProductId(id)
        }
    }


    // effect

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    useEffect(() => {
        document.addEventListener('click', handleOutsideClickShow);
        return () => {
            document.removeEventListener('click', handleOutsideClickShow);
        };
    }, []);

    useEffect(() => {
        const calculatedTotalQuantity = products.reduce((total, product) => total + product.quantity, 0);
        const calculatedTotalPrice = products.reduce((total, product) => {
            const productPrice = parseInt(product.price) || 0;
            return total + (productPrice * product.quantity);
        }, 0);
        setTotalQuantity(calculatedTotalQuantity);
        setTotalPrice(calculatedTotalPrice);
    }, [products]);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, products, productValue, totalPrice]);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' || currentPage === "/Publish/CardForm" || currentPage === "/build/CardForm" ? { xs: '12px 0px', md: '12px 10px' } : '24px 36px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            ref={gridRef} onClick={() => setBorder(true)}
            border={border && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '2px solid #0293e2' : '2px solid transparent'}>
            <Grid pb={'16px'}>
                <input
                    readOnly={isReadOnly || condition}
                    className="customHead-input"
                    placeholder={'Type a question'}
                    style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                    }}
                    value={productValue}
                    onChange={handlePhoneChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid className={'products'} pb={'30px'} borderBottom={'1px solid rgba(195,202,216,0.5)'}>
                <Grid className={'productList'} pb={'16px'}>
                    {
                        products.map(
                            (item, index) =>
                                <>
                                    <Grid className={'product'} bgcolor={'rgba(85, 115, 215, 0.06)'} borderRadius={'4px'}
                                        p={'15px'}
                                        mb={'16px'}
                                        display={{ xs: 'none', sm: 'flex' }} alignItems={'center'} width={'100%'}
                                        onClick={(event) => {
                                            event.stopPropagation();  // Stop propagation to the outer click event
                                            event.nativeEvent.stopImmediatePropagation();  // Stop immediate propagation
                                            setShow(true);
                                            setSelectedProductId(item.id);
                                        }}
                                        ref={productRefs.current[index]}
                                    >
                                        <Grid className={'details'} display={'flex'} alignItems={'flex-start'}
                                            width={!isReadOnly && selectedProductId === item.id ? '95%' : '100%'}
                                            justifyContent={'space-between'} pl={'10px'}
                                            borderLeft={!isReadOnly && selectedProductId === item.id ? '1px solid #e6e6e6' : 'none'}
                                        >
                                            <Grid className={'detail'} display={'flex'} alignItems={'center'}
                                                justifyContent={'flex-start'} gap={'16px'}>
                                                <Grid width={'24px'} height={'24px'} border={'1px solid #e6e6e6'}
                                                    display={'flex'}
                                                    alignItems={'center'} justifyContent={'center'}
                                                    onClick={() => { handleSetId(item.id) }}
                                                >
                                                    <Typography variant={'subtitle1'} color={'darkgray'}
                                                        pt={'4px'}
                                                        display={item.id === productId ? 'block' : 'none'}>
                                                        {convertToPersianNumber(item.id)}
                                                    </Typography>
                                                </Grid>
                                                <Grid width={'70px'} height={'70px'}>
                                                    <label htmlFor={`imageInput_${item.id}`}>
                                                        <Grid
                                                            style={{
                                                                position: 'relative',
                                                                width: '100%',
                                                                height: '100%',
                                                                border: '1px solid #e6e6e6',
                                                                padding: item.image ? '0px' : '4px',
                                                            }}
                                                        >
                                                            {item.image ? (
                                                                <img
                                                                    src={item.image}
                                                                    alt="Uploaded"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Grid
                                                                    display={'flex'}
                                                                    flexDirection={'column'}
                                                                    alignItems={'center'}
                                                                    justifyContent={'center'}
                                                                    border={'1px dashed #dee3f3'}
                                                                    width={'100%'}
                                                                    height={'100%'}
                                                                >
                                                                    <img
                                                                        src={'/assets/images/product_upload.svg'}
                                                                        alt="Uploaded"
                                                                        style={{
                                                                            width: '50%',
                                                                            height: '50%',
                                                                            objectFit: 'cover',
                                                                            marginTop: '20%',
                                                                        }}
                                                                    />
                                                                    <Typography variant={'subtitle1'}>آپلود</Typography>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                        {
                                                            isReadOnly || condition ?
                                                                <input
                                                                    id={`imageInput_${item.id}`}
                                                                    style={{ display: 'none' }}
                                                                />
                                                                :
                                                                <input
                                                                    id={`imageInput_${item.id}`}
                                                                    type="file"
                                                                    accept="image/*"
                                                                    style={{ display: 'none' }}
                                                                    onChange={(event) => handleImageUpload(event, item.id)}
                                                                />
                                                        }
                                                    </label>
                                                </Grid>
                                                <Grid>
                                                    <Grid>
                                                        <input
                                                            readOnly={isReadOnly || condition}
                                                            placeholder={'نام را وارد کنید'}
                                                            onChange={(event) => handleNameChange(event, item.id)}
                                                            value={item.name}
                                                            style={{
                                                                border: 'none',
                                                                outline: 'none',
                                                                userSelect: 'text',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'pre-wrap',
                                                                width: '100%',
                                                                fontSize: '14px',
                                                                fontWeight: 700,
                                                                lineHeight: 1.45,
                                                                color: 'rgb(44, 51, 69)',
                                                                backgroundColor: 'transparent'
                                                            }} />
                                                    </Grid>
                                                    <Grid>
                                                        <input placeholder={'توضیحات را وارد کنید'}
                                                            onChange={(event) => handleDescriptionChange(event, item.id)}
                                                            value={item.description}
                                                            readOnly={isReadOnly || condition}
                                                            style={{
                                                                border: 'none',
                                                                outline: 'none',
                                                                userSelect: 'text',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'pre-wrap',
                                                                width: '100%',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                lineHeight: 1.45,
                                                                color: HeadingColor,
                                                                backgroundColor: 'transparent'

                                                            }} />
                                                    </Grid>
                                                    <Grid display={'flex'} alignItems={'center'}
                                                        justifyContent={'flex-start'}
                                                        gap={'8px'} className="custom-select">
                                                        <Typography variant={'subtitle1'}
                                                            color={'#57647e'}>تعداد</Typography>
                                                        <select id="selectOption" value={item.quantity}
                                                            disabled={condition}
                                                            onChange={(event) => handleSelectChange(event, item.id)}
                                                            style={{ width: '43px', height: '34px' }}>
                                                            {[...Array(10).keys()].map((value) => (
                                                                <option key={value + 1} value={value + 1}>
                                                                    {convertToPersianNumber(value + 1)}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <>
                                                <Grid className={'price'} display={'flex'} alignItems={'center'}>
                                                    <input
                                                        readOnly={isReadOnly || condition}
                                                        placeholder={'قیمت را وارد کنید'}
                                                        onChange={(event) => handlePriceChange(event, item.id)}
                                                        value={item.price === '' && !show ? 'Free' : item.price}
                                                        style={{
                                                            direction: 'ltr',
                                                            border: 'none',
                                                            outline: 'none',
                                                            userSelect: 'text',
                                                            wordWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap',
                                                            width: '100px',
                                                            fontSize: '14px',
                                                            fontWeight: 500,
                                                            lineHeight: 1.45,
                                                            color: HeadingColor,
                                                            backgroundColor: 'transparent'

                                                        }}
                                                    />
                                                    {item.price !== '' && (
                                                        <Typography variant={'h6'}
                                                            color={HeadingColor}>تومان</Typography>
                                                    )}
                                                </Grid>
                                            </>
                                        </Grid>
                                        <Grid className={'delete'} display={!isReadOnly && selectedProductId === item.id ? 'flex' : 'none'}
                                            px={'16px'}
                                            width={selectedProductId === item.id ? '5%' : '0%'} flexDirection={'column'}
                                            alignItems={'center'}
                                            justifyContent={'center'} gap={'8px'} mr={'15px'}>
                                            <Grid width={'34px'} height={'34px'} border={'1px solid #DEE3F4'}
                                                bgcolor={'#ffffff'}
                                                borderRadius={'4px'} display={'flex'} alignItems={'center'}
                                                onClick={isReadOnly || condition ? null : (event) => handleDeleteIconClick(event, item.id)}
                                                justifyContent={'center'}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <DeleteIcon fontSize={'large'} style={{ color: 'darkgray' }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid display={{ xs: "block", sm: 'none' }} p={'15px'}
                                        mb={'16px'}
                                        bgcolor={'rgba(85, 115, 215, 0.06)'} borderRadius={'4px'}
                                        onClick={(event) => {
                                            event.stopPropagation();  // Stop propagation to the outer click event
                                            event.nativeEvent.stopImmediatePropagation();  // Stop immediate propagation
                                            setShow(true);
                                            setSelectedProductId(item.id);
                                        }}
                                        ref={productRefs.current[index]}
                                    >
                                        <Grid width={'100%'} height={'200px'} pb={'16px'}>
                                            <label htmlFor={`imageInput_${item.id}`}>
                                                <Grid
                                                    style={{
                                                        position: 'relative',
                                                        width: '100%',
                                                        height: '100%',
                                                        border: '1px solid #e6e6e6',
                                                        padding: item.image ? '0px' : '4px',
                                                    }}
                                                >
                                                    {item.image ? (
                                                        <img
                                                            src={item.image}
                                                            alt="Uploaded"
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    ) : (
                                                        <Grid
                                                            display={'flex'}
                                                            flexDirection={'column'}
                                                            alignItems={'center'}
                                                            justifyContent={'center'}
                                                            border={'1px dashed #dee3f3'}
                                                            width={'100%'}
                                                            height={'100%'}
                                                        >
                                                            <img
                                                                src={'/assets/images/product_upload.svg'}
                                                                alt="Uploaded"
                                                                style={{
                                                                    width: '150px',
                                                                    height: '140px',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />
                                                            <Typography variant={'subtitle1'}>آپلود</Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                {
                                                    currentPage === '/build' || currentPage === "/build/CardForm" || condition ?
                                                        <input
                                                            id={`imageInput_${item.id}`}
                                                            style={{ display: 'none' }}
                                                        />
                                                        :
                                                        <input
                                                            id={`imageInput_${item.id}`}
                                                            type="file"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={(event) => handleImageUpload(event, item.id)}
                                                        />
                                                }
                                            </label>
                                        </Grid>
                                        <Grid pb={'16px'}>
                                            <Grid>
                                                <input
                                                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                                                    placeholder={'نام را وارد کنید'}
                                                    onChange={(event) => handleNameChange(event, item.id)}
                                                    value={item.name}
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        userSelect: 'text',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'pre-wrap',
                                                        width: '100%',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        lineHeight: 1.45,
                                                        color: 'rgb(44, 51, 69)',
                                                        backgroundColor: 'transparent'
                                                    }} />
                                            </Grid>
                                            <Grid>
                                                <input
                                                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                                                    placeholder={'توضیحات را وارد کنید'}
                                                    onChange={(event) => handleDescriptionChange(event, item.id)}
                                                    value={item.description}
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        userSelect: 'text',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'pre-wrap',
                                                        width: '100%',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        lineHeight: 1.45,
                                                        color: HeadingColor,
                                                        backgroundColor: 'transparent'

                                                    }} />
                                            </Grid>
                                        </Grid>
                                        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} pb={'16px'}>
                                            <Grid display={'flex'} alignItems={'center'}
                                                justifyContent={'flex-start'}
                                                gap={'8px'} className="custom-select">
                                                <Typography variant={'subtitle1'}
                                                    color={'#57647e'}>تعداد</Typography>
                                                <Grid style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                    <select id="selectOption" value={item.quantity}
                                                        disabled={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                                                        onChange={(event) => handleSelectChange(event, item.id)}
                                                        style={{ width: '43px', height: '34px', maxHeight: '50px', overflowY: 'auto' }}>
                                                        {[...Array(5).keys()].map((value) => (
                                                            <option key={value + 1} value={value + 1} >
                                                                {convertToPersianNumber(value + 1)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Grid>
                                            </Grid>
                                            <Grid className={'price'} display={'flex'} alignItems={'center'}>
                                                <input
                                                    placeholder={'قیمت را وارد کنید'}
                                                    onChange={(event) => handlePriceChange(event, item.id)}
                                                    value={item.price === '' && !show ? 'Free' : item.price}
                                                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                                                    style={{
                                                        direction: 'ltr',
                                                        border: 'none',
                                                        outline: 'none',
                                                        userSelect: 'text',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'pre-wrap',
                                                        width: '100px',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        lineHeight: 1.45,
                                                        backgroundColor: 'transparent',
                                                        color: '#2e69ff'

                                                    }}
                                                />
                                                {item.price !== '' && (
                                                    <Typography variant={'h6'}
                                                        color={'#2e69ff'}>تومان</Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid className={'delete'} display={!isReadOnly && selectedProductId === item.id ? 'flex' : 'none'}
                                            width={selectedProductId === item.id ? '100%' : '0%'}
                                            px={'16px'}
                                            alignItems={'center'}
                                            justifyContent={'flex-end'} gap={'8px'} mr={'15px'}>
                                            <Grid width={'34px'} height={'34px'} border={'1px solid #DEE3F4'}
                                                bgcolor={'#ffffff'}
                                                borderRadius={'4px'}
                                                onClick={isReadOnly || condition ? null : (event) => handleDeleteIconClick(event, item.id)}
                                                display={isReadOnly ? 'none' : 'flex'} alignItems={'center'}
                                                justifyContent={'center'}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <DeleteIcon fontSize={'large'} style={{ color: 'darkgray' }} />
                                            </Grid>
                                            <Grid width={'34px'} height={'34px'} border={'1px solid #DEE3F4'}
                                                bgcolor={'#ffffff'}
                                                borderRadius={'4px'} display={isReadOnly ? 'none' : 'flex'} alignItems={'center'}
                                                justifyContent={'center'}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <SettingsIcon fontSize={'large'} style={{ color: 'darkgray' }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>

                        )
                    }

                </Grid>
                <Grid className={'addProduct'} width={'100%'} height={'42px'}
                    display={isReadOnly ? 'none' : 'flex'} alignItems={'center'}
                    justifyContent={'center'} bgcolor={'#EFF3FE'} border={'1px solid #5175F2'} borderRadius={'4px'}
                    style={{ cursor: 'pointer' }}
                    onClick={isReadOnly || condition ? null : handleProduct}
                >
                    <AddIcon style={{ color: '#5175F2' }} />
                    <Typography variant={'h5'} color={'#5175F2'} fontWeight={700}>اضافه کردن محصول</Typography>
                </Grid>
            </Grid>
            <Grid className={'total'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} py={'16px'}>
                <input
                    readOnly={isReadOnly || condition}
                    placeholder={'یک عنوان وارد کنید'}
                    onChange={handleTotalPrice}
                    value={totalPriceText}
                    style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: 'auto',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: 'rgb(44, 51, 69)',
                        backgroundColor: 'transparent'
                    }} />
                <Typography variant={'h6'} fontWeight={700}> {convertToPersianNumber(totalPrice)} تومان </Typography>
            </Grid>
        </Grid>
    )
}
export default ProductList
