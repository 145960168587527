import { Grid, Typography } from '@mui/material'
import React from 'react'
import FullName from './FullName'
import Email from './Email'
import Phone from './Phone'
import Address from './Address'
import SingleChoice from './SingleChoice'
import DropDown from './DropDown'
import MultiChoice from './MultiChoice'
import LongText from './LongText'
import Heading from './Heading'


const HomeLoanApplication = () => {


    const rentOrOwnHomeData = [
        { id: 1, label: 'صاحب خانه', checked: false },
        { id: 2, label: 'اجاره', checked: false },
    ]


    const yesOrNoHomeData = [
        { id: 1, label: 'بله', checked: false },
        { id: 2, label: 'خیر', checked: false },
    ]



    const HotelDropDownOptions = [
        'اقامتگاه اولیه',
        'اقامتگاه ثانویه',
        'سرمایه گذاری',
        'مطمئن نیستم',
    ]


    const loanHomeData = [
        { id: 1, label: 'بله', checked: false },
        { id: 2, label: 'خیر', checked: false },
        { id: 3, label: 'خریدار نقدی', checked: false },
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست وام مسکن'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={"نام و نام خانوادگی"} />
                <Email header={'ایمیل'} />
                <Phone header={'شماره تلفن'} />
                <Address />
                <SingleChoice header={'آیا شما صاحب خانه هستید یا اجاره می دهید؟'} options={rentOrOwnHomeData} />
                <SingleChoice header={'آیا قبل از خرید خانه جدید باید خانه خود را بفروشید؟'} options={yesOrNoHomeData} />
                <DropDown header={'نوع خرید'} options={HotelDropDownOptions} />
                <SingleChoice header={'آیا از قبل واجد شرایط وام هستید؟'} options={loanHomeData} />
                <SingleChoice header={'آیا شما توسط یک مشاور املاک نمایندگی دارید؟'} options={yesOrNoHomeData} />
                <LongText header={'نظرات یا سوالات:'} />
            </Grid>
        </Grid>
    )
}

export default HomeLoanApplication