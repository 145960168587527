import { Grid, Typography } from '@mui/material'
import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from 'react-router-dom';

const CreateFormNavbar = ({ title, subTitle, back, flag , linkTitle }) => {


    const navigate = useNavigate()


    const handleBackNavigate = () => {
        navigate(back)
    }


    const handleCloseSelect = () => {
        navigate('/Main')
    }


    return (
        <Grid mb={'50px'}>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'32px'}>
                <Grid onClick={handleBackNavigate} width={'90px'} height={'40px'} borderRadius={'30px'} p={'8px 12px'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'4px'} bgcolor={'#e3e5f5'} style={{ cursor: 'pointer' }}>
                    <KeyboardBackspaceIcon fontSize='large' style={{ color: '#6f76a7', rotate: '180deg' }} />
                    <Typography variant='h6' color={'#6f76a7'} fontWeight={700} style={{ display: { xs: 'none', sm: 'block' } }}>بازگشت</Typography>
                </Grid>
                <Grid onClick={handleCloseSelect} display={'flex'} alignItems={'center'} justifyContent={'center'} width={'40px'} height={'40px'} borderRadius={'50%'} bgcolor={'#e3e5f5'} style={{ cursor: 'pointer' }}>
                    <CloseIcon fontSize='large' style={{ color: '#6f76a7' }} />
                </Grid>
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Typography textAlign={'center'} variant='h2' color={'#0a1551'} fontWeight={700} mb={'10px'}>{title}</Typography>
                <Grid display={'flex'} flexDirection={{xs:'column' , md:'row'}} alignItems={'center'} gap={'2px'}>
                    <Typography sx={{display:{xs:'none' , md:'block'}}} textAlign={'center'} variant='h5' color={'#6f76a7'} fontWeight={700}>{subTitle}</Typography>
                    <Typography sx={{display:{xs:'block' , md:'none'}}} textAlign={'center'} variant='h6' color={'#6f76a7'} fontWeight={700}>{subTitle}</Typography>
                    <Link to={'/createForm/Scratch'} style={{ display: flag ? 'block' : 'none' }}>
                        <Typography sx={{display:{xs:'block' , md:'none'}}} textAlign={'center'} variant='h6' color={'#09f'} fontWeight={700}>{linkTitle}</Typography>
                        <Typography sx={{display:{xs:'none' , md:'block'}}} textAlign={'center'} variant='h5' color={'#09f'} fontWeight={700}>{linkTitle}</Typography>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CreateFormNavbar