import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import DatePickers from './DatePicker';
import Email from './Email';
import Phone from './Phone';
import Address from './Address';
import ShortText from './ShortText';
import SingleChoice from './SingleChoice';
import FileUpload from './FileUpload';
import LongText from './LongText';

const EmployeeRegistrationForm = () => {


    const veteranData = [
        { id: 1, label: 'من یک جانباز هستم', checked: false },
        { id: 2, label: 'من یک جانباز معلول ویژه هستم', checked: false },
        { id: 3, label: "من یک جانباز اخیرا جدا شده ام", checked: false },
        { id: 4, label: "من یک جانباز حفاظت شده دیگر هستم", checked: false },
        { id: 5, label: "من جانباز نیستم", checked: false },
        { id: 6, label: "ترجیح میدهم پاسخ ندهم ", checked: false },
    ]


    const genderData = [
        { id: 1, label: 'زن', checked: false },
        { id: 2, label: 'مرد', checked: false },
    ]


    const  disabilitiesData = [
        { id: 1, label: 'من هیچ معلولیتی ندارم', checked: false },
        { id: 2, label: 'من دو یا چند اختلال و/یا شرایط پزشکی ناتوان کننده دارم.', checked: false },
        { id: 3, label: 'من یک مشکل یادگیری خاص دارم (مثلاً نارساخوانی/دیسپراکسی/AD(H)D', checked: false },
        { id: 4, label: 'من یک اختلال اجتماعی / ارتباطی مانند سندرم آسپرگر / سایر اختلالات طیف اوتیسم دارم', checked: false },
        { id: 5, label: 'من یک بیماری طولانی مدت یا وضعیت سلامتی مانند سرطان، HIV، دیابت، بیماری مزمن قلبی یا صرع دارم.', checked: false },
        { id: 6, label: 'من یک نقص جسمی یا مشکلات حرکتی دارم (مثلاً مشکل در استفاده از بازوها/استفاده از ویلچر یا عصا)', checked: false },
        { id: 7, label: 'من ناشنوا هستم یا اختلال شنوایی جدی دارم', checked: false },
        { id: 8, label: 'من نابینا هستم یا یک نقص بینایی جدی دارم که با عینک اصلاح نشده است', checked: false },
        { id: 9, label: 'ترجیح می دهید پاسخ ندهید', checked: false },
        { id: 10, label: 'من یک معلولیت، نقص یا وضعیت پزشکی دارم که در اینجا ذکر نشده است', checked: false },
    ]


    const raceData = [
        { id: 1, label: 'بومی آمریکایی یا بومی آلاسکا', checked: false },
        { id: 2, label: 'آسیایی', checked: false },
        { id: 3, label: 'سیاه پوست یا آفریقایی آمریکایی', checked: false },
        { id: 4, label: 'بومی هاوایی یا سایر ساکنان جزایر اقیانوس آرام', checked: false },
        { id: 5, label: 'سفید پوست', checked: false },
        { id: 6, label: 'دو یا چند نژاد', checked: false },
        { id: 7, label: 'دیگر', checked: false },
    ]


    const  typeOfWorkData = [
        { id: 1, label: 'دائمی', checked: false },
        { id: 2, label: 'موقت', checked: false },
        { id: 2, label: 'هر دو', checked: false },
        { id: 2, label: 'دیگر', checked: false },
    ]

    
    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم ثبت نام کارمندان'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={"نام و نام خانوادگی"} />
                <DatePickers header={'تاریخ تولد'} />
                <Email header={'ایمیل'} />
                <Phone header={'شماره تلفن'}/>
                <Address header={'آدرس'} />
                <ShortText header={'درخواست برای موقعیت :'} />
                <SingleChoice header={'نوع کار'} options={typeOfWorkData} />
                <FileUpload header={'لطفا رزومه را آپلود کنید'} />                
                <LongText header={'اطلاعات تکمیلی'} />
                <SingleChoice header={'وضعیت جانبازی (لطفاً گزینه ای را انتخاب کنید که وضعیت شما را به بهترین شکل توصیف می کند)'} options={veteranData} />
                <SingleChoice header={'جنسیت'} options={genderData} />
                <SingleChoice header={'معلولیت ها'} options={disabilitiesData} />
                <SingleChoice header={'نژاد'} options={raceData} />
                <LongText header={'یادداشت های اضافی'}/>
            </Grid>
        </Grid>
    )
}

export default EmployeeRegistrationForm