import React, { useState, useEffect } from 'react'
import CreateFormNavbar from '../Component/CreateFormNavbar'
import MainHeader from '../Component/MainHeader'
import { Button, Grid, Typography } from '@mui/material'
import Modal from 'react-modal';
import cardTemplateData from '../data/cardTemplateData';
import { useFormContext } from '../Context/Context';
import { useNavigate } from 'react-router-dom'



const TemplateMainPage = () => {

    const { setDrawerData } = useFormContext();


    const [visit, setVisit] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);


    const navigate = useNavigate()

    const filteredData = cardTemplateData.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const openModal = (template) => {
        setSelectedTemplate(template);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedTemplate(null);
    };


    const handleUseTemplate = (id) => {
        let findUseIndex = filteredData.findIndex(item => item.id === id)
        setDrawerData(filteredData[findUseIndex]?.state)
        navigate('/build')
    }

    return (
        <Grid>
            <MainHeader />
            <Grid p={'28px 32px'}>
                <CreateFormNavbar title={'یک الگو را انتخاب کنید'} subTitle={'کاوش در قالب های آماده برای ایجاد فرم در چند دقیقه یا'} back={'/createForm'} flag={true} linkTitle={'از ابتدا فرم ایجاد کنید'} />
                <Grid width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                    <Grid width={{ xs: '100%', md: '50%' }} display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'32px'}>
                        <input
                            style={{
                                padding: '0px 16px',
                                outline: 'none',
                                border: 'none',
                                height: '48px',
                                width: '100%',
                                borderRadius: '4px',
                                backgroundColor: visit ? 'rgb(255, 255, 255)' : 'rgb(227, 229, 245)',
                                color: 'rgb(111, 118, 167)',
                                boxShadow: visit ? 'rgb(189, 206, 251) 0px 0px 0px 3px' : 'transparent 0px 0px 0px 3px',
                                fontSize: '18px',
                            }}
                            placeholder='در تمام قالب ها جستجو کنید'
                            onClick={() => { setVisit(true) }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Grid>
                    <Grid container gap={'40px'} alignItems={'center'} justifyContent={'center'}>
                        {
                            filteredData.map(
                                item =>
                                    <Grid item xs={12} sm={5} lg={3}>
                                        <Grid width={'100%'} mb={'48px'}>
                                            <Grid className='CardImage' mb={'24px'} borderRadius={'16px'} sx={{ cursor: 'pointer' }}
                                                onClick={() => openModal(item)}
                                            >
                                                <Grid className='Image' boxShadow={'rgba(37, 45, 91, 0.04) 0px 0px 2px 0px, rgba(84, 95, 111, 0.16) 0px 4px 8px 0px'}>
                                                    <img src={item.src} alt='' style={{ width: '100%', height: '100%' }} />
                                                </Grid>
                                            </Grid>
                                            <Grid className='CardTitle' mb={'40px'}>
                                                <Typography variant='h5' fontWeight={700} color={'rgba(10,21,81,1)'}>{item.title}</Typography>
                                            </Grid>
                                            <Grid className='CardButton' display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'8px'}>
                                                <Button variant='outlined' color='success'
                                                    onClick={() => { handleUseTemplate(item.id) }}
                                                    sx={{
                                                        p: '12px 0px',
                                                        fontSize: '16px',
                                                        fontWeight: 700,
                                                        width: '100%',
                                                        '&:hover': {
                                                            backgroundColor: 'success.main',
                                                            color: 'white',
                                                        }
                                                    }}>از قالب استفاده کنید</Button>
                                                <Button variant='outlined' color='primary'
                                                    onClick={() => openModal(item)}
                                                    sx={{
                                                        p: '12px 0px',
                                                        fontSize: '16px',
                                                        fontWeight: 700,
                                                        width: '100%',
                                                        '&:hover': {
                                                            backgroundColor: 'primary.main',
                                                            color: 'white',
                                                        }
                                                    }}>پیش نمایش</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            )
                        }

                    </Grid>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={{
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                marginTop: '50px',
                                transform: 'translate(-50%, -50%)',
                                width: '95%',
                                maxWidth: '800px',
                                maxHeight: '80vh',
                                padding: 0,
                                borderRadius: '8px',
                                overflow: 'scroll'
                            },
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }}
                    >
                        {selectedTemplate && (
                            <Grid className='modal'>
                                <Grid className='header' bgcolor={'white'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} p={{ xs: '16px', md: '16px 32px' }}>
                                    <Typography sx={{ display: { xs: 'none', md: 'block' } }} variant='h2' fontWeight={700} color={'#0a1551'}>{selectedTemplate.title}</Typography>
                                    <Typography sx={{ display: { xs: 'block', md: 'none' } }} variant='h6' fontWeight={700} color={'#0a1551'}>{selectedTemplate.title}</Typography>
                                    <Button onClick={() => { handleUseTemplate(selectedTemplate.id) }} variant='contained' color='success' sx={{ fontSize: { xs: '12px', md: '16px' }, p: { xs: '6px 10px', md: '12px 28px' }, borderRadius: '4px' }}>
                                        از قالب استفاده کنید
                                    </Button>
                                </Grid>
                                <Grid className='content' p={'16px'} bgcolor={'#f3f3fe'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    {selectedTemplate.modalContent}
                                </Grid>
                            </Grid>
                        )}
                    </Modal>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TemplateMainPage