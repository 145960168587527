import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { AllFormMessageIcon } from './Icons'
import { useNavigate } from 'react-router-dom'
import { useFormLimit } from '../Context/FormLimitContext'
import { toast } from 'react-toastify'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const AllFormMessage = () => {


    const {
        canCreateForm,
    } = useFormLimit();


    const navigate = useNavigate()


    const CustomToast = ({ message, state }) => {
        return (
            <Grid display="flex" alignItems="start" justifyContent="end" gap="5px" style={{
                backgroundColor: 'white',
                color: state === 'success' ? 'green' : 'red',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
            }}>
                {state === 'success' ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
                {message}
            </Grid>
        );
    };


    const handleCreateForm = () => {

        if (!canCreateForm) {
            toast(<CustomToast message={'شما نمی‌توانید فرم جدیدی ایجاد کنید. حداکثر تعداد ۱۰ فرم مجاز است.'} state={'error'} />);
        } else {

            navigate('/createForm')
        }

    }


    return (
        <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={'150px'} width={'100%'} mx={'auto'}>
            <Grid mb={'24px'}>
                <AllFormMessageIcon />
            </Grid>
            <Grid mb={'28px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant='h5' color={'#000'} fontWeight={700} mb={'8px'}>شما هنوز هیچ فرمی ندارید</Typography>
                <Typography variant='h5' color={'#545f6f'} fontWeight={500}>فرم های شما در اینجا ظاهر می شود.</Typography>
            </Grid>
            <Grid width={'100%'}>
                <Button
                    onClick={handleCreateForm}
                    disabled={!canCreateForm}
                    variant='outlined'
                    sx={{
                        width: '100%',
                        bgcolor: '#fff',
                        border: '1px solid #ff6100',
                        color: '#ff6100',
                        fontSize: '16px',
                        p: '8px 0px',
                        "&:hover": {
                            bgcolor: '#ff6100',
                            color: 'white',
                            border: '1px solid #ff6100'
                        }
                    }}
                >
                    فرم ایجاد کنید
                </Button>
            </Grid>
        </Grid>
    )
}

export default AllFormMessage