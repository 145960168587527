import { Grid, Typography } from '@mui/material'
import React from 'react'
import ProductList from './ProductList'
import FullName from './FullName'
import Phone from './Phone'
import Email from './Email'
import Address from './Address'
import LongText from './LongText'
import Heading from './Heading'

const CustomSimpleOrderForm = () => {
    return (
        <Grid p={{xs:'40px 13px' , md:'40px 52px'}} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{xs:'72px 0px' , md:'72px 42px'}}>
            <Heading header={'سفارش خود را در اینجا ثبت کنید'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <ProductList />
                <FullName />
                <Phone  />
                <Email />
                <Address header={'آدرس تحویل'} />
                <LongText header={'دستورالعمل های ویژه تحویل'} />
            </Grid>
        </Grid>
    )
}

export default CustomSimpleOrderForm